/**
 * A Table serves as a View (and often also a Controller) of a class of EBOs (the Model). Its
 * store property and its filter and compare methods determine which objects appear in the table and
 * in what order. Each of those objects is displayed as one or more rows, managed by a TableEntry.
 */
import ActionNode = require("Everlaw/UI/ActionNode");
import Arr = require("Everlaw/Core/Arr");
import Base = require("Everlaw/Base");
import Button = require("Everlaw/UI/Button");
import Checkbox = require("Everlaw/UI/Checkbox");
import Cmp = require("Everlaw/Core/Cmp");
import DateBox = require("Everlaw/UI/DateBox");
import DateUtil = require("Everlaw/DateUtil");
import Dom = require("Everlaw/Dom");
import E = require("Everlaw/Entities");
import eventUtil = require("dojo/_base/event");
import { defined } from "Everlaw/Core/Is";
import { toDefaultTableSelection } from "Everlaw/Filtering/Filtering";
import * as Filtering from "Everlaw/Filtering/Filtering";
import Icon = require("Everlaw/UI/Icon");
import Input = require("Everlaw/Input");
import Is = require("Everlaw/Core/Is");
import Popover = require("Everlaw/UI/Popover");
import Project = require("Everlaw/Project");
import SortColumnHeader = require("Everlaw/UI/SortColumnHeader");
import TextBox = require("Everlaw/UI/TextBox");
import Tooltip = require("Everlaw/UI/Tooltip");
import TripleCheckbox = require("Everlaw/UI/TripleCheckbox");
import UiWidget = require("Everlaw/UI/Widget");
import Util = require("Everlaw/Util");
import Validated = require("Everlaw/UI/Validated");
import fx = require("dojo/_base/fx");
import dojo_on = require("dojo/on");
import { Precision } from "Everlaw/DateTimePrecision";
import { isInView } from "Everlaw/Dom";
import { makeHorizontallyScrollableWithArrows } from "Everlaw/Input";
import { ColorTokens } from "design-system";
import { getProjectDateDisplayFormat } from "Everlaw/ProjectDateUtil";
import { SCROLLBAR_WIDTH } from "Everlaw/UI";
import { getFocusDiv, makeFocusable } from "Everlaw/UI/FocusDiv";
import { CheckboxState } from "Everlaw/UI/TripleCheckbox";
import { SwitchingIconButton, SwitchingIconButtonParams } from "Everlaw/UI/SwitchingIconButton";

/**
 * Manages the View of a single EBO in an associated Table. User code should NOT construct instances
 * of this class (the Table itself constructs an instance for each visible EBO). Instead, override
 * the class; e.g.:
 *
 *  let myTable = new Table({
 *      entryClass: class extends Table.Entry<...> {
 *          onClick() { ... } (whatever methods you want to override)
 *      }
 *  })
 *
 * Of course, you can lift out your declared class into some named variable if you wish.
 *
 * Note that this class is publicly available as Table.Entry. It is declared here before Table
 * because it is used in the Table definition.
 **/

abstract class TableEntry<OBJ extends Base.Object, DATA extends Table.RowData> {
    // Commonly overridden properties

    /**
     * An array of cell functions where the ith entry is used by the default implementation of
     * populateCell(i). The function are called in the context of this TableEntry.
     *
     * Any destroyables (e.g., tooltips) created by these functions should be managed appropriately.
     * For cells there are several ways to handle cleanup depending on how the destroyables are
     * used:
     * - Destroyables that get regenerated on every update should be returned by the cells function
     *   to be destroyed before the next update.
     * - Destroyables that get reused for every update should be added to this.destroyables, to be
     *   cleaned up at the end of the row's life. As a legacy shim, everything set on p.data,
     *   including but not limited to p.destroyables, will be destroyed as well, so you can put
     *   them in there.
     *
     * Generally, widgets/icons/etc created only once on the first call (i.e. in a p.first block)
     * should get added to row.destroyables/p.data/p.data.destroyables, while anything else created
     * on every call should be returned from the method to be destroyed on refresh.
     *
     * For example:
     *
     *  cells = [
     *      function(row, td, isNew) {
     *          // This icon gets destroyed every time the row is refreshed
     *          return UI.icon("one-off",...);
     *      },
     *      function(row, td, isNew) {
     *          if (isNew) {
     *              row.reusedIcon = UI.icon("reused",...)
     *              // This icon only gets destroyed when the row is destroyed. Alternatively, you
     *              // could override row.destroy to handle this cleanup.
     *              row.destroyables.push(row.reusedIcon);
     *          }
     *      },
     *      // legacy example
     *      function(p) {
     *          if (p.firstTime) {
     *              p.data.icon = UI.icon("reused",...);
     *              // This icon only gets destroyed when the row is destroyed.
     *          }
     *      },
     *      ...
     *  ]
     *
     * If cells are not provided, or if the ith cell is not defined, this.table.cells[i] (which has
     * a different API from this) will be used instead. In general, there should be some cells
     * function for each item in this.table.columns.
     */
    cells: ((td: HTMLTableCellElement, isNew: boolean) => Util.Destroyable | void)[];

    abstract initCells();

    /**
     * If defined, a click handler will be added to the table row that will perform this callback.
     * It is called in this context, and it receives the following arguments:
     *  - the click event
     *  - the cell that was clicked on, or null
     *  - the index of the cell that was clicked on, or null
     */
    onClick(evt: Event, cell: HTMLTableCellElement, index: number) {}
    // Can't declare a method without an implementation, so we need this hack to check if onClick
    // is *really* implemented
    hasOnClick() {
        return this.onClick !== TableEntry.prototype.onClick;
    }

    /**
     * Returns the TR for this table for the default this.createRows implementation.
     */
    createRow() {
        if (this.table.constructRow) {
            // legacy shim
            return this.table.constructRow(this.ebo);
        }
        return Dom.tr();
    }

    /**
     * Returns an array of TR nodes for this entry. The array must have at least one element.
     * Because most tables have a single row, the default implementation returns an array containing
     * the result of calling this.createRow().
     */
    createRows() {
        return [this.createRow()];
    }

    // Public API

    /**
     * The EBO encompassed by this entry.
     */
    ebo: OBJ;

    /**
     * A reference to the Table that this Entry belongs to.
     */
    table: Table<OBJ, DATA>;

    /**
     * The array of destroyable items (other than this.rows) that need to be destroyed along with
     * the entry.
     */
    destroyables: Util.Destroyable[] = [];

    /**
     * The array of TR elements belonging to this entry.
     */
    rows: HTMLTableRowElement[];

    /**
     * Provides the first (and often only) TR node for this entry.
     */
    tr: HTMLTableRowElement;

    // Internal API

    /**
     * The scroll pad TD(s), if there are any.
     */
    _scrollPads: HTMLElement[];

    /**
     * An array of destroyable cell items that should be destroyed every time the row is refreshed
     * (and when it is destroyed).
     */
    private refreshDestroyables: Util.Destroyable[] = [];

    constructor(ebo: OBJ, table: Table<OBJ, DATA>) {
        this.ebo = ebo;
        this.table = table;
        this.rows = this.createRows();
        this.tr = this.rows[0];
        table.onInitRow && table.onInitRow(this.tr, this.ebo);
        // Convenience destroyables storage for our data object.
        this.data.destroyables = [];
        if (this.hasOnClick() || table.onClick /* legacy shim */) {
            Dom.addClass(this.tr, "action");
            this.destroyables.push(
                dojo_on(this.tr, Input.tap, (evt) => {
                    this._onClick(evt);
                }),
            );
        }
        this.initCells();
        table.columns.forEach((attrs, i) => {
            Dom.place(Dom.td(attrs), this.tr);
            const destroyable = this.populateCell(i, true);
            if (destroyable) {
                this.refreshDestroyables.push(destroyable);
            }
        });
        // See note about scroll pad in Table.refresh.
        if (!table.thead) {
            this._scrollPads = this.createScrollPads();
        }
    }

    populateCell(i: number, isNew: boolean) {
        const td = <HTMLTableCellElement>this.tr.cells[i];
        return this.cells[i](td, isNew);
    }

    /**
     * Creates and returns this entry's scroll pads.
     */
    createScrollPads() {
        return this.rows.map((tr) => {
            return Table.createScrollPad(Dom.td, tr);
        });
    }

    destroy() {
        Dom.destroy(this.rows);
        // Shim: destroy all of the values in this.data.  We expect that everything in there is
        // "disposable", and either gets destroyed here or is ignored by Util.destroy.
        // This also handles destroying this.data.destroyables.
        this.data && Util.destroy(Object.values(this.data));
        Util.destroy(this.destroyables);
        Util.destroy(this.refreshDestroyables);
    }

    refresh() {
        Util.destroy(this.refreshDestroyables);
        this.refreshDestroyables = [];
        for (let i = 0, len = this.table.columns.length; i < len; i++) {
            const destroyable = this.populateCell(i, false);
            if (destroyable) {
                this.refreshDestroyables.push(destroyable);
            }
        }
    }

    callout() {
        this.rows.forEach(this.calloutRow, this);
    }

    calloutRow(tr: HTMLElement) {
        fx.animateProperty({
            node: tr,
            duration: this.table.calloutDuration,
            properties: {
                // selected color
                backgroundColor: {
                    start: this.table.calloutColor,
                    end: ColorTokens.BACKGROUND_SECONDARY,
                },
            },
            onEnd: function () {
                Dom.style(tr, "background", "");
            },
        }).play();
    }

    showScrollPad(show: boolean) {
        if (this._scrollPads) {
            Dom.show(this._scrollPads, show);
        }
    }

    _onClick(evt: Event) {
        if (!this.tr.parentNode || (!this.hasOnClick() && !this.table.onClick) /* legacy shim */) {
            return;
        }
        const tr = evt.currentTarget;
        let node = <HTMLElement>evt.target;
        // we don't want to report clicks on/in input elements
        if (
            node instanceof HTMLInputElement
            || node instanceof HTMLTextAreaElement
            || node.contentEditable === "true"
        ) {
            return;
        }
        // We want to figure out which cell was clicked on. We cannot just walk up the DOM looking
        // for a TD, because there could be a nested table. Instead, we have to walk up looking for
        // our row, keeping track of the last TD along the way. When we find our TR, the value of
        // lastTd will be our cell.
        let lastTd: HTMLTableCellElement;
        while (node && node !== tr) {
            // Some dijit input elements are actually off-screen, and their on-screen counterparts
            // have a role="presentation" attribute. The problem is that clicking on, e.g., a button
            // causes a click event on the presentation node (the one inside the table) that bubbles
            // up to here, and only afterward is the dijit input element's click event fired. In
            // other words, there is no way to prevent the event from bubbling to here.
            //
            // To remedy this situaton, we look for dijit presentation nodes as we are traversing
            // the DOM looking for lastTd. If we find one, we stop the event here.
            if (Dom.getAttr(node, "role") === "presentation") {
                return;
            }
            if (node instanceof HTMLTableCellElement) {
                lastTd = node;
            }
            node = node.parentElement;
        }
        if (this.hasOnClick()) {
            this.onClick(evt, lastTd, lastTd && lastTd.cellIndex);
        } else {
            // legacy shim
            // Use _entryIndex here so that default entryIndex can be overridden and
            // default onclick will still function
            this.table.onClick(
                this.ebo,
                this.table._entryIndex(this.ebo),
                lastTd ? lastTd.cellIndex : -1,
                evt,
                this.table,
            );
        }
    }

    // Legacy API: When these uses are removed, we can delete this section.

    /**
     * A simple object. Future code should simply add items directly to this (the Table.Entry).
     */
    data = <DATA>{}; // legacy shim
}

/**
 * The basic Table object. It builds an HTML table display from the ground up, unless a TABLE or
 * TBODY element is provided as the parentNode. It does NOT include any widget components that exist
 * outside the table; for that, refer to the Table.Widget subclass.
 */
class Table<OBJ extends Base.Object, DATA extends Table.RowData> {
    /**
     * When setting the table to a shimmering loading state, use this number of rows.
     * This value is fairly arbitrary - it should just be large enough to cover the entire
     * height of the table.
     */
    static readonly SHIMMER_TABLE_ROWS = 40;

    // Construction-time properties

    /**
     * Required; the Base.Object class that this table contains.
     */
    store: Base.Store<OBJ>;

    /**
     * The Table.Entry class to use for managing each included EBO.
     */
    entryClass: new (ebo: OBJ, table: Table<OBJ, DATA>) => TableEntry<OBJ, DATA>;

    /**
     * The node or node ID at which to place the table. If the node is a table or tbody element, it
     * will be used as-is. If it is some other element (or null), the table will be created.
     */
    parentNode: HTMLElement;

    /**
     * An array whose length determines the number of columns in the table. Each element of the
     * array should be an object that will serve as the attributes for TH and TD elements in that
     * column; null can be used in place of an empty object.
     */
    columns: any[];

    /**
     * An array of functions used for populating the cells. The function is called with the
     * following argument:
     *  p: a hash with the following elements:
     *      o: the object
     *      tr: the tr dom node
     *      td: the TD for this cell
     *      me: the Table object
     *      data: a persistent hash specific to this object. Client can store anything in here and
     *          retrieve it in a future cell, or in this cell upon re-rendering
     *      first: true iff this is the first time the function is being called for this td
     *
     * Any destroyables (tooltips, event listeners, widgets,...) put into p.data will be destroyed
     * when the object is removed from the table. You can also use the catch-all p.data.destroyables
     * array.
     *
     * This property should only be used for the simplest of tables. As the table gets more complex,
     * you should consider overriding entryClass instead.
     *
     * This is required unless entryClass provides appropriate cell methods.
     *
     * Future API
     *
     * A subsequent commit will alter this API. Each function will receive three arguments instead
     * of one:
     *  td: the cell to populate
     *  e: the table entry object, which has the following properties (former representation in
     *     parentheses):
     *      - ebo (o)
     *      - tr
     *      - table (me)
     *  isNew (first)
     *
     * Instead of using data, additional values can just be added directly to e.
     */
    cells: Table.CellCallback<OBJ, DATA>[];

    /**
     * An array of TH contents. This value will be used if parentNode is not a TBODY.
     */
    header: Dom.Content[];

    /**
     * The message to display when there are no entries in the table. When null, no message will be
     * displayed at all.
     */
    empty: Dom.Content;

    /**
     * Any extra styling you want to give to the empty message
     */
    emptyStyle: Dom.StyleProps;

    /**
     * Used to override shimmering behavior of table. If true, table is always shimmering.
     */
    isPermanentlyShimmering = false;

    /**
     * Base comparator for the table, as passed in as mixin through {@link Table.TableParams}.
     * In most cases, you should use {@link fullCompare} since that takes into account any applied
     * with {@link columnFilterModule}.
     */
    compare(o1: OBJ, o2: OBJ): number {
        return 0;
    }

    /**
     * Provides a standard compare function for sorting the visible entries in this table. It
     * accepts two EBOs as arguments. This does not have to be consistent with object equality - any
     * ties are broken by Cmp.full.
     */
    fullCompare(o1: OBJ, o2: OBJ) {
        return this.columnFilterModule
            ? this.columnFilterModule.getSortCmp(this._compareWithFallback.bind(this))(o1, o2)
            : this._compareWithFallback(o1, o2);
    }

    /**
     * Base filter for the table, as passed in as mixin through {@link Table.TableParams}.
     * In most cases, you should use {@link fullFilter} since that takes into account any applied
     * with {@link columnFilterModule}.
     */
    filter(o: OBJ): boolean {
        return true;
    }

    /**
     * Provides a mechanism for filtering objects out of the table view; given an EBO returned from
     * the store, return true iff it should be displayed in the table.
     *
     * If the filter is reassigned or its behavior changes, either `Base.publish` (e.g., via
     * `Base.set(OBJ, ...)`) or `this.update()` must be called for the new filter to take effect.
     */
    fullFilter(o: OBJ): boolean {
        return (
            this.filter(o) && (this.columnFilterModule ? this.columnFilterModule.filter(o) : true)
        );
    }

    /**
     * Provides a mechanism for pagination.
     * undefined if no pagination is defined for the table.
     */
    pagination: Table.Pagination;
    /**
     * Set to true while fetching entries from the back-end.
     */
    private loading = false;
    /**
     * used to avoid updating the table with stale results:
     * tracks the current query number and increments for each query sent.
     * if the query id is not the same by the time the request resolves,
     * don't update the table with its results
     */
    private curFetchId = 0;
    private getCurFetchId(increment?: boolean) {
        return increment ? ++this.curFetchId : this.curFetchId;
    }

    /**
     * Provides a way to add filter/sorting buttons.
     * undefined if no filter module is defined for the table.
     */
    columnFilterModule: Table.FilterSortModule<OBJ>;
    onFilterChange: (module: Table.FilterSortModule<OBJ>) => void;
    onShimmerToggle: (shimmering: boolean) => void;

    /**
     * The index into this.tbody where EBO rows should begin. This allows the user to add arbitrary
     * rows at the beginning and end of the table. Negative values are not currently supported. When
     * null, entryIndex is set at construction time to the value of this.tbody.childElementCount, so
     * that entry rows will be inserted after any existing rows, and any rows added after the table
     * is constructed will always come after the entries (even after a refresh).
     *
     * It's safe for entryIndex to be greater than the number of elements that are actually in the
     * table. This means that callers can safely construct the table and then add n rows (where n =
     * entryIndex) to the beginning of the tbody afterwards.
     *
     * The empty cell (when there is one) is also displayed at this position.
     *
     * This value can be safely modified as long as this.refresh() is called afterward.
     */
    entryIndex: number;

    /**
     * A scrollpad is an extra empty cell included in each row that is used in scrolling tables to
     * keep the cells in the same vertical alignment regardless of whether or not there is a
     * scrollbar. The idea is for the cell to be present when there is no scrollbar and hidden when
     * there is.
     *
     * In order for scrollpad functionality to work correctly, this value must be a positive number
     * that reflects the maximum number of rows that can fit in the table without the need for a
     * scrollbar. When <= 0, there is no scrollPad.
     */
    scrollPad: number;

    /**
     * Navigation button divs for pagination - must be placed manually alongside the table.
     * Will be undefined unless a Pagination is provided.
     */
    navigation: HTMLElement;

    /**
     * A string indicating the CSS maximum height of the table contents. This value is generally set
     * along with scrollPad. It is ignored if parentNode is a TABLE or a TBODY, in which case the
     * caller is responsible for managing the maximum height.
     */
    maxHeight: string;

    /**
     * Indicates whether the table should callout entries as usual. If skipCallout is false, new
     * rows will never be called out.
     */
    skipCallout: boolean;
    calloutDuration = 1000;
    calloutColor = ColorTokens.BACKGROUND_CALLOUT;

    // Public API

    /**
     * When this.parentNode is null, this is set to the outermost container node that contains the
     * table. This allows Dom.place to be used on a Table after contruction.
     */
    node: HTMLElement;

    /**
     * The table's thead, only when it has been built by the table. This happens when parentNode is
     * not a TBODY and header is non-null.
     */
    thead: HTMLElement;

    /**
     * The parent node for all rows in the body of the table.
     */
    tbody: HTMLElement;

    /**
     * The wrapper for the table as its displayed on the page.
     */
    tableWrapper: HTMLElement;

    /**
     * The array of entries that are currently displayed in the table; never null after
     * construction. User code can iterate over this array in order to visit each entry, but it
     * must not make any modifications.
     *
     * This array is modified internally, most notably when the linked EBOs are updated, so be
     * extremely vigilant about how it's used. In particular, don't expect a given index to refer to
     * the same entry at different times.
     *
     * If pagination is included in the table, this array includes ONLY what is loaded.
     * For a list of all entries (post-filter), use {@link filteredSortedObjects}.
     */
    entries: TableEntry<OBJ, DATA>[];

    /**
     * The ordered array of all filtered objects. Not all may be rendered in the table if
     * pagination is active.
     */
    filteredSortedObjects: OBJ[];

    /**
     * Whether or not to manually resolve objects in #update.
     * This should be set to true if this table uses objects not in Base.
     */
    manuallyResolveObjectUpdates: boolean;

    /**
     * Set when calling {@link #onInitialLoad} through {@link #handleInitialLoad} to
     * track the state of the table's onInitialLoad calls.
     */
    initialLoadState = Table.InitialLoadState.NOT_LOADED;

    /**
     * An optional function to call each time a row is initialized
     */
    onInitRow: (tr: HTMLTableRowElement, ebo: OBJ) => void;

    onAfterRefresh: (self: Table<OBJ, DATA>) => void;

    /**
     * Returns this table's entry for the given ebo, if there is one, and null otherwise.
     */
    getEntry(ebo: OBJ) {
        return this.entries[this._entryIndex(ebo)] || null;
    }

    /**
     * Returns the number of entries represented by this table; not all may be displayed.
     * If this table does not lazy load, this will be equal to filteredSortedObjects.length
     */
    getTotalEntries(): number {
        return (
            (this.isLazyLoading()
                ? this.pagination.lazyLoader.totalEntries
                : this.filteredSortedObjects.length) ?? 0
        );
    }

    /**
     * Updates this.entries to reflect the current state of `this.store.getAll()`, `this.filter`,
     * and `this.compare`. If provided, and if they are visible in the table, the entries of the
     * given objs are refreshed and their `entry.callout` methods are called. If they are not
     * provided, all entries are refreshed, and no callouts are performed.
     */
    update(objs?: OBJ | OBJ[], then?: () => void): void {
        // Determine which entries need to be refreshed and called out according to objs.
        const refreshAll = !objs;
        const refreshAndCallout: { [key: string]: boolean } = {};
        Arr.wrap(objs).forEach((ebo) => {
            refreshAndCallout[ebo.getKey()] = true;
        });
        // If we're lazy loading, we re-fetch immediately afterwards, so no point in doing this
        if (objs && this.manuallyResolveObjectUpdates && !this.isLazyLoading()) {
            // Refreshing the table relies on instance-equality to update objects in the table
            // (each TableEntry has a reference to a specific ebo object that's only set once).
            // This works if we're relying on Base since Base handles that instance-equality
            // through Base.set, updating the instance referenced by ebo. If we cannot assume things
            // are in Base (e.g. with Custodians), this resolution needs to be handled manually
            // to make edits to existing objects show up in the table.
            const objIdMap = new Map();
            Arr.wrap(objs).forEach((obj) => objIdMap.set(obj.getKey(), obj));
            for (let i = 0; i < this.filteredSortedObjects.length; i++) {
                const fsObj = this.filteredSortedObjects[i];
                const newObj = objIdMap.get(fsObj.getKey());
                if (newObj) {
                    // `_mixin` replicates the operation performed
                    // by Base.set in Base#findOrCreate to replace
                    // the old data with new data
                    fsObj._mixin(newObj);
                }
            }
        }
        this.overwriteEntryObjectsAndRefresh(undefined, refreshAll, refreshAndCallout, then);
    }

    /**
     * Updates using the provided objs without reapplying sorting/filtering.
     * This avoids an expensive re-sort/filter on page change.
     */
    overwriteEntryObjects(objs: OBJ[], then?: () => void): void {
        this.overwriteEntryObjectsAndRefresh(objs, false, undefined, then);
    }

    /**
     * If filteredSortedObjs are provided, uses that value to overwrite filteredSortedObjects,
     * then refreshes the table without recalculating filter/sort order.
     * If no filteredSortedObjs are provided, recalculates filter/sort order and uses that
     * as the new filteredSortedObjects.
     */
    private overwriteEntryObjectsAndRefresh(
        filteredSortedObjs?: OBJ[],
        refreshAll = false,
        refreshAndCallout?: { [key: string]: boolean },
        then?: () => void,
    ) {
        // Track each of the entries that are already in the table prior to the update. This will
        // allow us to determine which entries remain in the table, for which objects we need to
        // create new entries, and which entries are no longer in the table.
        const entries: { [key: string]: TableEntry<OBJ, DATA> } = {};
        this.entries.forEach((e) => {
            entries[e.ebo.getKey()] = e;
        });

        // Repopulate the entries from scratch if no filteredSortedObjs are provided
        if (filteredSortedObjs) {
            this.filteredSortedObjects = filteredSortedObjs;
            const pool = this.isLazyLoading()
                ? filteredSortedObjs
                : filteredSortedObjs.slice(
                      this.getFirstPaginationIndex(),
                      this.getLastPaginationIndex(),
                  );
            this.entries = pool.map((o) =>
                this.mapEntry(o, entries, refreshAndCallout?.[o.getKey()] ?? false, refreshAll),
            );
            this.cleanupEntries(entries);
            then?.();
        } else {
            this.sortAndMapEntries(entries, refreshAndCallout, refreshAll, (results) => {
                this.entries = results;
                this.cleanupEntries(entries);
                then?.();
            });
        }
    }

    private cleanupEntries(entries: { [key: string]: TableEntry<OBJ, DATA> }): void {
        // Destroy the entries that are no longer displayed in the table.
        Object.values(entries).forEach((e) => {
            this.onDestroy(e);
            e.destroy();
        });
        this.refresh();
    }

    protected onDestroy(entry: TableEntry<OBJ, DATA>) {}

    /**
     * Whether or not this table is lazy loading, i.e. it always tries to fetch the next
     * page from a back-end endpoint and does not hold all objects in memory.
     */
    isLazyLoading(): this is { pagination: { lazyLoader: Table.LazyLoader } } {
        return !!this.pagination?.lazyLoader;
    }

    /**
     * Whether or not the table is currently in the process of loading entries (i.e. from back-end).
     * Always false if this table is not lazy-loading.
     */
    isLoading(): boolean {
        return this.isLazyLoading() && this.loading;
    }

    getLastPage(): number {
        return this.pagination
            ? Math.max(Math.ceil(this.getTotalEntries() / this.pagination.entriesPerPage), 1)
            : 1;
    }

    /**
     * The first array index of the current pagination wrt the entire pool of objects.
     */
    getFirstPaginationIndex(): number {
        return this.pagination ? this.pagination.curPage * this.pagination.entriesPerPage : 0;
    }

    /**
     * The last user-displayed index of the current pagination wrt the entire pool of objects.
     * This is synonymous with "last array index + 1".
     */
    getLastPaginationIndex(): number {
        return this.pagination
            ? Math.min(
                  this.getTotalEntries(),
                  (this.pagination.curPage + 1) * this.pagination.entriesPerPage,
              )
            : this.getTotalEntries();
    }

    scrollToColumn(header: string): void {
        if (!header) {
            return;
        }
        let totalWidth = 0;
        const viewWidth = this.tableWrapper.clientWidth;
        const firstDisplayedPixel = this.getScrollLeft();
        const lastDisplayedPixel = firstDisplayedPixel + viewWidth;

        for (let i = 0; i < this.columnHeaderData.length; i++) {
            const data = this.columnHeaderData[i];
            const headerWidth = data.div.clientWidth;
            if (data.header === header) {
                // check if this header is within bounds of the current view
                const startInBounds =
                    totalWidth >= firstDisplayedPixel && totalWidth <= lastDisplayedPixel;
                const endInBounds =
                    totalWidth + headerWidth >= firstDisplayedPixel
                    && totalWidth + headerWidth <= lastDisplayedPixel;
                if (!startInBounds || !endInBounds) {
                    this.setScrollLeft(totalWidth);
                }
                break;
            }
            totalWidth += headerWidth;
        }
    }

    /**
     * If lazy loading, fetches results from back-end using the filtering and sorting parameters
     * and calls then asynchronously.
     * Otherwise, filters and sorts all objects from the store and calls then synchronously.
     */
    protected getFilteredAndSortedEntries(then: (objs: OBJ[]) => void): void {
        if (this.isLazyLoading()) {
            const lazyLoader = this.pagination.lazyLoader;
            if (lazyLoader.shouldShowEmptyResults?.()) {
                this.filteredSortedObjects = [];
                lazyLoader.totalEntries = 0;
                then(this.filteredSortedObjects);
                // Since handleInitialLoad often (circuitously) references the table itself
                // and getFilteredAndSortedEntries is called during table construction,
                // this callback needs to be slightly delayed so that the table
                // is fully constructed (and assigned to whatever variable used by
                // the caller) when this is executed.
                // This is mostly a side effect of the Table constructor _also_
                // being responsible for initialization instead of having init be
                // a separate step.
                setTimeout(() => this.handleInitialLoad(0), 10);
                return;
            }
            const params = this.createBackendQueryData(this.pagination.curPage);
            this.setLoadingState(true);
            const prevFetchId = this.getCurFetchId(true);
            lazyLoader.fetcher(params).then((result) => {
                const curFetchId = this.getCurFetchId();
                if (curFetchId !== prevFetchId) {
                    // we've done a newer fetch in the meantime, so these results are
                    // stale. therefore, we should toss them out.
                    return;
                }
                this.filteredSortedObjects = result.objects as OBJ[];
                if (Is.defined(result.total)) {
                    lazyLoader.totalEntries = result.total;
                }
                then(this.filteredSortedObjects);
                this.handleInitialLoad(result.total ?? 0);
                this.setLoadingState(false);
            });
            return;
        }
        this.filteredSortedObjects = Arr.sort(
            this.store.getAll().filter((o) => this.fullFilter(o)),
            {
                cmp: (a, b) => this.fullCompare(a, b),
            },
        );
        then(this.filteredSortedObjects);
        this.handleInitialLoad(this.filteredSortedObjects.length);
    }

    private setLoadingState(isLoading = true): void {
        this.loading = isLoading;
        this.setShimmer(isLoading);
        this.updatePageDisplay();
    }

    /**
     * Set isPermanentlyShimmering and update table view if value of isPermanentlyShimmering is
     * changed.
     */
    setPermanentlyShimmering(value: boolean) {
        if (this.isPermanentlyShimmering !== value) {
            this.isPermanentlyShimmering = value;
            this.setLoadingState(this.loading);
        }
    }

    private handleInitialLoad(total: number): void {
        if (this.initialLoadState !== Table.InitialLoadState.NOT_LOADED) {
            return;
        }
        this.initialLoadState = Table.InitialLoadState.CALLED_INITIAL_LOAD;
        this.onInitialLoad();
        this.pagination?.lazyLoader?.onInitialLoad?.(total);
        this.initialLoadState = Table.InitialLoadState.FINISHED_INITIAL_LOAD;
    }

    protected onInitialLoad(): void {}

    protected sortAndMapEntries(
        entries: { [key: string]: TableEntry<OBJ, DATA> },
        refreshAndCallout: { [key: string]: boolean } | undefined,
        refreshAll: boolean,
        then: (entries: TableEntry<OBJ, DATA>[]) => void,
    ): void {
        this.getFilteredAndSortedEntries((filteredSortedEntries) => {
            if (!this.isLazyLoading()) {
                // If we aren't lazy-loading from back-end, we need to get a sub-list
                // of the entries based on our pagination controls
                filteredSortedEntries = filteredSortedEntries.slice(
                    this.getFirstPaginationIndex(),
                    this.getLastPaginationIndex(),
                );
            }
            then(
                filteredSortedEntries.map((o) =>
                    this.mapEntry(o, entries, refreshAndCallout?.[o.getKey()] ?? false, refreshAll),
                ),
            );
        });
    }

    createBackendQueryData(page: number): Filtering.Params {
        const params: Filtering.Params = {
            sort: undefined,
            filters: [],
            pageNumber: page,
            pageSize: this.pagination.entriesPerPage,
            useKeysetPagination: this.columnFilterModule.useKeysetPagination,
            reverseResultOrder: false,
        };
        if (this.columnFilterModule) {
            this.columnFilterModule.populateParams(params);
            if (!this.columnFilterModule.useKeysetPagination && page > this.getLastPage() / 2) {
                // Not using keyset pagination, so to reduce the overhead of manifesting OFFSET LIMIT,
                // go from the edge that has the least stuff to manifest. Effectively we manifest at
                // most half the query instead of the whole query...
                params.pageNumber = this.getLastPage() - page - 1;
                params.reverseResultOrder = true;
            }

            if (params.sort && this.columnFilterModule.useKeysetPagination) {
                const curPage = page;
                const prevPage = this.pagination.previousPage ?? 0;
                const lastPageIdx = this.getLastPage() - 1;
                // Special handling for first page, last page, or jump > 1 page:
                // We either trivially go to front/end, or need to do OFFSET (by breaking out of the current condition)
                if (Math.abs(curPage - prevPage) > 1 || curPage === 0 || curPage === lastPageIdx) {
                    if (curPage === 0 || curPage !== lastPageIdx) {
                        // We are jumping to some arbitrary page or the first page;
                        // Either no way or no need to get a pagination key for this one
                        // so we can only resolve it using OFFSET.
                        // By skipping this block, we default to using OFFSET LIMIT.
                    } else if (curPage === lastPageIdx) {
                        // We are either jumping to the start or the end.
                        // Ex: DB=[ABC DEF GHJ K]   pageSize=3  sort=ASC
                        // If jumping to the end:
                        //   - Reverse sort order (-> [KJH GFE DCB A])
                        //   - Take the top pageSize results (-> [KJH])
                        //   - Set reverseResultOrder so the back-end knows to reverse the order of the
                        //     results before passing back to front-end (-> [HJK])
                        // If jumping to the start:
                        //   - Trivial; no special handling needed
                        params.reverseResultOrder = true;
                        params.pageNumber = 0;
                        // If fetching the very last page, it may not be a full last page
                        params.pageSize =
                            this.getTotalEntries() % this.pagination.entriesPerPage
                            || this.pagination.entriesPerPage;
                    }
                } else {
                    let paginationObject =
                        this.filteredSortedObjects[this.filteredSortedObjects.length - 1];
                    if (curPage < prevPage) {
                        // Logic here: if our new page is less than our current page, then we are moving
                        // "backwards," so our backend query should actually filter in reverse-sort order
                        // on the condition that column, then take the top pageSize results.
                        // Ex: DB=[ABC DEF GHJ K]   pageSize=3  curPage=2  prevPage=3  sort=ASC
                        //   => filteredSortedObjects = [GHJ]
                        // To query for the next 3, we want to:
                        // - Query for objects < G (filteredSortedObjects[0] -> [ABC DEF])
                        // - Reverse the sort order to DESC (-> [FED CBA])
                        // - Take the top pageSize results (3) (-> [FED])
                        // - Set reverseResultOrder so the back-end knows to reverse the order of the
                        //   results before passing back to front-end (-> [DEF])
                        params.reverseResultOrder = true;
                        paginationObject = this.filteredSortedObjects[0];
                    }
                    const value = paginationObject[params.sort.column];
                    params.sort.paginationKey = {
                        id: paginationObject.id as number,
                        value: value ? `${value}` : undefined,
                        secondary:
                            this.columnFilterModule.getUniqueSortableValue?.(paginationObject),
                    };
                }
            }
        }
        return params;
    }

    protected mapEntry(
        obj: OBJ,
        entries: { [key: string]: TableEntry<OBJ, DATA> },
        refreshAndCallout: boolean,
        refreshAll: boolean,
    ): TableEntry<OBJ, DATA> {
        let e = entries[obj.getKey()];
        if (e) {
            // It was already in the table. Remove it from entries so that we don't destroy it
            // later, and refresh if necessary.
            delete entries[obj.getKey()];
            if (refreshAll || refreshAndCallout) {
                e.refresh();
            }
        } else {
            e = this._createEntry(obj);
        }
        if (refreshAndCallout && !this.skipCallout) {
            e.callout();
        }
        return e;
    }

    /**
     * Refreshes the table display to reflect the current value of this.entries, this.empty, and
     * this.entryIndex.
     */
    refresh() {
        this._destroyEmpty();
        if (this.entries.length === 0) {
            if (this.empty != null) {
                this._emptyTr = Dom.create(
                    "tr",
                    { class: "table-empty-row" },
                    this.tbody,
                    this.entryIndex,
                );
                const td = Dom.create(
                    "td",
                    {
                        colSpan: this.columns.length,
                        style: `padding-left: 10px; width: ${this.thead ? this.thead.clientWidth + "px" : "auto"}`,
                        class: "description table-empty-row-placeholder",
                        content:
                            this.columnFilterModule && this.columnFilterModule.hasFilterApplied()
                                ? "No filtered results"
                                : this.empty,
                    },
                    this._emptyTr,
                );
                if (this.emptyStyle) {
                    Dom.style(td, this.emptyStyle);
                }
            }
            if (this.thead) {
                Dom.hide(this._scrollPads);
            }
        } else {
            const overflowingMaxHtDiv =
                !!this.maxHtDiv && this.tbody.scrollHeight > this.maxHtDiv.clientHeight;
            const scrollbarVisible =
                this.scrollPad > 0 && (this.entries.length > this.scrollPad || overflowingMaxHtDiv);

            if (this.thead) {
                // When we have created our own header, the contents never have a scroll pad.
                // Instead, both the headers and the contents take up the full horizontal table
                // width when there is no scroll bar. When there is a scroll bar in the body, the
                // header contains a scroll pad so that it is properly aligned.
                Dom.show(this._scrollPads, scrollbarVisible);
            }
            let insertPoint: Node = this.tbody.childNodes.item(this.entryIndex); // null if out of bounds
            for (
                let x = 0;
                (!this.pagination || x < this.pagination.entriesPerPage) && x < this.entries.length;
                x++
            ) {
                const e = this.entries[x];
                e.rows.forEach((tr) => {
                    // Note that insertBefore places tr at the end of tbody if insertPoint is null.
                    // Additionally, it's perfectly fine to place an element that is already in the
                    // DOM -- it will be detached from its old position and moved to its new one.
                    //
                    // Since we start from the beginning, the elements naturally bubble up to their
                    // new position if the sort order has changed.
                    //
                    // When the table is refreshed, FocusDivs are sometimes set as insertPoint that
                    // are no longer children of tbody. In IE, this causes errors. Here, we default
                    // to inserting at null if the insertPoint is a FocusDiv.
                    if (Dom.hasClass(<HTMLElement>insertPoint, "focusDiv")) {
                        this.tbody.insertBefore(tr, null);
                    } else if (tr !== insertPoint) {
                        this.tbody.insertBefore(tr, insertPoint);
                    }
                    insertPoint = tr.nextSibling;
                    // If the entry has an onClick method, it should be focusable and clickable by
                    // a keyboard user for accessibility reasons. Here, handlers are attached to all
                    // rows of the entry and added to the entry's destroyables list.
                    if (e.hasOnClick() || this.onClick) {
                        const wasFocusable = !!getFocusDiv(tr);
                        const focusDiv = makeFocusable(tr, "focus-text-style", "after");
                        // We need to re-place the focusDivs in tables in case they're sorted, so
                        // that the insertion order isn't compromised.
                        Dom.place(focusDiv.node, this.tbody, "last");
                        if (!wasFocusable) {
                            e.destroyables.push(
                                focusDiv,
                                Input.fireCallbackOnKey(focusDiv.node, [Input.ENTER], (evt) =>
                                    e._onClick(evt),
                                ),
                                dojo_on(focusDiv.node, "focusin", () => {
                                    if (this.maxHtDiv && !isInView(tr, this.maxHtDiv)) {
                                        tr.scrollIntoView({ block: "center" });
                                    }
                                }),
                            );
                        }
                    }
                });
                e.showScrollPad(this.scrollPad > 0 && !scrollbarVisible);
            }
        }
        if (this.maxHtDiv) {
            Dom.style(this.maxHtDiv, "maxHeight", this.maxHeight);
        }
        this.onAfterRefresh?.(this);
    }

    /**
     * If objects is provided, overwrites filteredSortedEntries with the list, then reloads only
     * the current page without re-calculating sort/filter.
     * If not provided, destroys all table entries, refetches the EBOs, and repopulates the table.
     */
    reload(objects?: OBJ | OBJ[], then?: () => void) {
        const thenUpdatePage = () => {
            then?.();
            this.updatePageDisplay();
        };
        if (this.horizontalScrollSync) {
            // Preserve horizontal scroll position between table refreshes
            const left = this.horizontalScrollSync.getLeftScroll();
            this.clear();
            if (objects) {
                this.overwriteEntryObjects(Arr.wrap(objects), thenUpdatePage);
            } else {
                this.update(undefined, thenUpdatePage);
            }
            this.horizontalScrollSync.setLeftScroll(left);
            this.horizontalScrollSync.setRightScroll(left);
            return;
        }
        this.clear();
        if (objects) {
            this.overwriteEntryObjects(Arr.wrap(objects), thenUpdatePage);
        } else {
            this.update(undefined, thenUpdatePage);
        }
    }

    /**
     * Makes all table columns shimmer by inserting shimmerDiv into the table's body.
     * This div is hidden when the table is not shimmering, and shown when the table is.
     */
    setShimmer(shimmering: boolean): void {
        if (this.isPermanentlyShimmering || shimmering) {
            Dom.hide(this.tbody);
            Dom.show(this.shimmerDiv);

            // generate this on the fly since most tables won't need this
            if (this.shimmerDiv.children.length === 0) {
                const shimmertbody = Dom.tbody();
                for (let i = 0; i < Table.SHIMMER_TABLE_ROWS; i++) {
                    const tr = Dom.tr();
                    this.columns.forEach((attrs, _i) => {
                        const td = Dom.td(
                            attrs,
                            Dom.div({
                                class: "inner-shimmer bb-table__loading-shimmer",
                            }),
                        );
                        // add separately to avoid mutating attrs
                        Dom.addClass(td, "shimmering-cell");
                        Dom.place(td, tr);
                    });
                    Dom.place(tr, shimmertbody);
                }
                Dom.place(shimmertbody, this.shimmerDiv);
            }
        } else {
            Dom.hide(this.shimmerDiv);
            Dom.show(this.tbody);
        }
        this.onShimmerToggle?.(this.isPermanentlyShimmering || shimmering);
    }

    /**
     * Destroys all table entries and the empty row.
     */
    clear() {
        this.entries?.forEach((e) => this.onDestroy(e));
        Util.destroy(this.entries);
        this._destroyEmpty();
        this.entries = [];
    }

    destroy() {
        this.clear();
        Util.destroy(this.destroyables);
    }

    /**
     * If filteredSortedObjs are provided, uses the provided list as the new filteredSortedObjects
     * without recalculating using sort/filter.
     */
    updateFilter(filteredSortedObjs?: OBJ[], totalEntries?: number, then?: () => void): void {
        // Whenever our filters change, go back to the first page
        if (this.pagination) {
            this.pagination.curPage = 0;
            this.pagination.previousPage = undefined;
            if (Is.defined(totalEntries) && this.pagination.lazyLoader) {
                this.pagination.lazyLoader.totalEntries = totalEntries;
            }
        }
        this.reload(filteredSortedObjs, () => {
            then?.();
        });
    }

    // Internal API

    /**
     * The array of destroyable items (other than this._emptyTr and this.entries) that need to be
     * destroyed along with the table.
     */
    destroyables: Util.Destroyable[] = [];

    /**
     * The empty TR, only when it is currently being displayed. If this.empty is null, it is never
     * displayed.
     */
    _emptyTr: HTMLElement;

    /**
     * When this.thead is non-null, this holds the scroll pad for our table header.
     */
    _scrollPads: HTMLElement[];

    /**
     * When maxHeight is set, this is the div that has the actual max height applied.
     */
    maxHtDiv: HTMLElement;

    /**
     * When maxHeight is set, this is the div that holds the table for the headers.
     */
    theadDiv: HTMLElement;

    /**
     * Whether or not to add a 'hover' class to tr's
     */
    useHoverClass = true;

    columnHeaderData: Table.ColumnHeaderData[];
    private paginationData: Table.PaginationBarData;
    private horizontalScrollSync: Table.TableSynchronizerHorizontal;

    private createPaginationNavbar(additionalClass?: string): HTMLElement {
        this.paginationData = {
            pageDisplay: Dom.div({ class: "padded-text" }),
            indexDisplay: Dom.div({ class: "padded-text" }),
            firstPageButton: this.createPaginationButton("chevron-bar-left-20", "First", () => 0),
            prevPageButton: this.createPaginationButton(
                "chevron-left-20",
                "Previous",
                () => this.pagination.curPage - 1,
            ),
            lastPageButton: this.createPaginationButton(
                "chevron-bar-right-20",
                "Last",
                () => this.getLastPage() - 1,
            ),
            nextPageButton: this.createPaginationButton(
                "chevron-right-20",
                "Next",
                () => this.pagination.curPage + 1,
            ),
        };

        const clazz = additionalClass
            ? `table-pagination-navbar ${additionalClass}`
            : "table-pagination-navbar";
        return Dom.div(
            { class: clazz },
            Dom.div({ class: "display-count" }, this.paginationData.indexDisplay),
            Dom.div(
                { class: "page-controls" },
                this.paginationData.pageDisplay,
                Dom.div(
                    { class: "nav-buttons" },
                    this.paginationData.firstPageButton.node,
                    this.paginationData.prevPageButton.node,
                    this.paginationData.nextPageButton.node,
                    this.paginationData.lastPageButton.node,
                ),
            ),
        );
    }

    private createPaginationButton(
        icon: string,
        tooltip: string,
        getPageToMoveTo: () => number,
    ): Button.IconButton {
        return new Button.IconButton({
            iconClass: icon,
            tooltip,
            onClick: () => {
                if (this.isLoading()) {
                    // Disable navigation while the current page is loading
                    return;
                }
                this.advanceToPage(getPageToMoveTo());
            },
        });
    }

    constructor(params: Table.TableParams<OBJ, DATA>) {
        Object.assign(this, params);
        if (this.pagination) {
            this.navigation = this.createPaginationNavbar(this.pagination.additionalClass);
        }

        if (params.prepopulatedFilteredSortedObjects) {
            this.filteredSortedObjects = params.prepopulatedFilteredSortedObjects;
        }

        if (this.parentNode) {
            this.parentNode = Dom.node(this.parentNode);
        }
        this.buildDom();
        if (this.entryIndex == null) {
            this.entryIndex = this.tbody.childElementCount;
        }
        if (!this.isLazyLoading()) {
            // Don't subscribe if we are lazy loading (store is mostly ignored if we are)
            this.destroyables.push(this.store.subscribe((objs) => this.update(objs)));
        }

        if (params.syncHeaderHorizontally) {
            this.horizontalScrollSync = new Table.TableSynchronizerHorizontal(this);
        }
        // Add prepopulated filters/sort if existing;
        // this needs to be done after the Dom has been built, but before the table
        // has reloaded (and populated its entries)
        if (this.columnFilterModule) {
            params.previousFilters && this.columnFilterModule.setFilters(params.previousFilters);
            params.previousSort && this.columnFilterModule.setSort(params.previousSort);
            delete params.previousFilters;
            delete params.previousSort;
        }
        if (params.skipInitialReload) {
            this.setShimmer(true);
        } else {
            this.reload(this.filteredSortedObjects, () => {
                this.pagination && this.setPage(this.pagination.curPage, false);
            });
        }

        if (this.columnFilterModule) {
            this.columnFilterModule.onFilterUpdate = () => {
                this.updateFilter(undefined, undefined, () => {
                    this.onFilterChange?.(this.columnFilterModule);
                });
            };
            this.destroyables.push(this.columnFilterModule);
        }
    }

    protected updatePageDisplay(): void {
        if (!this.pagination) {
            return;
        }
        if (this.isLoading()) {
            // Don't allow navigation while we're waiting for a REST request
            this.paginationData.firstPageButton.setDisabled(true);
            this.paginationData.prevPageButton.setDisabled(true);
            this.paginationData.lastPageButton.setDisabled(true);
            this.paginationData.nextPageButton.setDisabled(true);
            return;
        }
        const lastPage = this.getLastPage();
        const page = this.pagination.curPage;
        this.paginationData.pageDisplay.textContent = `Page ${(this.pagination.curPage + 1).toLocaleString()} \
            of ${lastPage.toLocaleString()}`;

        const lastIndex = this.getLastPaginationIndex();
        // Take the min here since if there are no results, we want to display "0-0" instead of "1-0"
        const firstIndex = Math.min(lastIndex, this.getFirstPaginationIndex() + 1);
        const total = this.getTotalEntries();
        this.paginationData.indexDisplay.textContent = `${firstIndex.toLocaleString()}-${lastIndex.toLocaleString()} \
            of ${total.toLocaleString()}`;
        this.paginationData.firstPageButton.setDisabled(page == 0);
        this.paginationData.prevPageButton.setDisabled(page == 0);
        this.paginationData.lastPageButton.setDisabled(page == lastPage - 1);
        this.paginationData.nextPageButton.setDisabled(page == lastPage - 1);
    }

    advanceToPage(to: number): void {
        const lastPage = this.getLastPage();
        const page = Util.clamp(to, 0, lastPage);
        this.setPage(page, true, undefined, () => {
            this.pagination.onPageChange?.(page);
        });
    }

    private getNewScrollHeight(fromPage: number, toPage: number): number {
        const diff = Math.abs(toPage - fromPage);
        const bottomOfPage = this.maxHtDiv.scrollHeight;
        if (diff > 1) {
            // Jumping to first or last page
            if (toPage === 0) {
                return 0;
            }
            if (toPage === this.getLastPage() - 1) {
                return bottomOfPage;
            }
        }
        // navigating to next page, scroll to top
        // navigating to prev page, scroll to bottom
        if (toPage < fromPage) {
            return bottomOfPage;
        }
        return toPage !== 0 && toPage === fromPage ? this.getScrollTop() : 0;
    }

    /**
     * @param autoScrollTopBottom if true, will automatically scroll to the top if advancing to a
     *                            next page, or to the bottom if advancing to a prev page
     */
    setPage(
        page: number,
        refresh = true,
        explicitFSObjects?: OBJ[],
        then?: (pageChanged: boolean) => void,
        autoScrollTopBottom = true,
    ): void {
        page = Math.max(0, page);

        this.pagination.previousPage = this.pagination.curPage;
        this.pagination.curPage = page;

        const scrollTarget = this.getNewScrollHeight(this.pagination.previousPage, page);
        const thenAndScroll = (pageChanged: boolean) => {
            // need to arbitrarily delay the scroll setting to give the page a chance to render the DOM
            // otherwise the scroll doesn't resolve correctly if scrolling to the bottom
            autoScrollTopBottom && setTimeout(() => this.setScrollTop(scrollTarget), 1);
            then?.(pageChanged);
        };
        if (refresh) {
            if (this.isLazyLoading()) {
                if (explicitFSObjects) {
                    this.filteredSortedObjects = explicitFSObjects;
                    this.reload(this.filteredSortedObjects, () => {
                        thenAndScroll(true);
                    });
                    return;
                } else {
                    this.reload(undefined, () => {
                        thenAndScroll(true);
                    });
                    return;
                }
            } else {
                this.reload(this.filteredSortedObjects);
            }
        }
        thenAndScroll(true);
    }

    setScrollTop(height: number) {
        if (this.maxHtDiv) {
            this.maxHtDiv.scrollTop = height;
        }
    }

    getScrollTop() {
        if (this.maxHtDiv) {
            return this.maxHtDiv.scrollTop;
        }
        return 0;
    }

    setScrollLeft(left: number): void {
        if (this.theadDiv) {
            this.theadDiv.scrollLeft = left;
        }
    }

    getScrollLeft(): number {
        if (this.theadDiv) {
            return this.theadDiv.scrollLeft;
        }
        return 0;
    }

    rebuildHeader(newHeader: Dom.Content[]): void {
        if (!this.header || this.header.length !== newHeader.length) {
            return;
        }
        this.header = newHeader;
        const oldThead = this.thead;
        const newThead = this.buildThead(Dom.div());
        Dom.place(newThead, oldThead, "after");
        Dom.remove(oldThead);
    }

    /**
     * Populates this.tbody, this.thead (when this.parentNode is not a tbody and this.header is not
     * null), and this.node (only when this.parentNode is null). This method is called after the
     * constructor params have been mixed in and this.parentNode (possibly null) has been resolved.
     *
     * Any created items that need to be destroyed (e.g., outermost DOM nodes, tooltips) must be
     * indicated by adding them to this.destroyables.
     */
    protected buildDom() {
        if (!this.parentNode) {
            this.node = this.buildTable();
            Dom.addClass(this.node, "table");
        } else if (this.parentNode.tagName === "TBODY") {
            // Nothing to build
            this.tbody = this.parentNode;
        } else if (this.parentNode.tagName === "TABLE") {
            Dom.addClass(this.parentNode, "table");
            // We've already been given a table, so use it.
            this.buildThead(this.parentNode);
            this.buildTbody(this.parentNode);
        } else {
            Dom.place((this.node = this.buildTable()), this.parentNode);
        }
    }

    protected shimmerDiv: HTMLElement;

    /**
     * Creates and returns a DOM node that contains our Table, assigning this.tbody in the process.
     */
    protected buildTable(): HTMLElement {
        const tableAttrs = { class: this.header ? "table" : "table no-header" };
        if (this.maxHeight) {
            // We implement a table with a maxHeight by wrapping the table in a div that has its
            // maxHeight set.
            const maxHtDiv = Dom.div({
                style: { maxHeight: this.maxHeight },
                class: "overflow-y-auto",
            });
            this.maxHtDiv = maxHtDiv;
            this.shimmerDiv = Dom.div({ class: "table" });
            Dom.hide(this.shimmerDiv);
            Dom.place(this.shimmerDiv, maxHtDiv);
            this.buildTbody(Dom.create("table", tableAttrs, maxHtDiv));
            if (this.header) {
                // When creating the header, we put the thead in an entirely separate, non-scrolling
                // table, with everything wrapped in a containing DIV.
                this.theadDiv = Dom.div({ class: "thead-div hide-scrollbar" });
                const container = Dom.div({}, this.theadDiv);
                this.buildThead(Dom.create("table", tableAttrs, this.theadDiv));
                Dom.place(maxHtDiv, container);
                return container;
            }
            return maxHtDiv;
        }
        const table = Dom.table(tableAttrs);
        this.buildThead(table);
        this.buildTbody(table);
        return table;
    }

    /**
     * Creates the thead element with the contents of this.header, if this.header is provided. The
     * resulting thead is placed in the given table and made available at this.thead.
     *
     * Returns the thead or null if it was not created.
     */
    protected buildThead(table: HTMLElement) {
        this.columnHeaderData = [];
        if (this.header) {
            this.thead = Dom.create("thead", table);
            const tr = Dom.create("tr", this.thead);

            this.header.forEach((thContent, i) => {
                const headerNode = Dom.create("th", this.columns[i], tr);
                if (this.columnFilterModule && Is.string(thContent)) {
                    this.columnHeaderData.push({
                        div: headerNode,
                        header: thContent,
                    });

                    const headerFilter = this.columnFilterModule.getColumnFilter(thContent);
                    if (headerFilter) {
                        Dom.addContent(
                            headerNode,
                            this.createFilterHeader(thContent, headerFilter),
                        );
                        return;
                    }
                }
                Dom.addContent(headerNode, thContent);
            });
            this._scrollPads = [Table.createScrollPad(Dom.th, tr)];
        }
        return this.thead;
    }

    protected createFilterHeader(header: string, filter: Table.Filter<OBJ, unknown>): HTMLElement {
        filter.setTable(this);
        const sortColumnHeader = this.columnFilterModule.createSortableHeader(
            header,
            filter.definition.key,
            (col) => {
                if (this.initialLoadState !== Table.InitialLoadState.FINISHED_INITIAL_LOAD) {
                    // If we've not initially loaded yet when calling onSort, return
                    // early without reloading the table.
                    // This prevents double-triggering of a refresh if, e.g., the table
                    // started with some set of initial filters, since we call setFilter
                    // on each one to handle that.
                    // The table will reload later anyways (since it hasn't yet).
                    return;
                }
                this.updateFilter(undefined, undefined, () => {
                    this.onFilterChange?.(this.columnFilterModule);
                });
            },
        );

        Dom.place(
            Dom.div({ class: "filter-button" }, filter.filterIcon.node),
            sortColumnHeader.getNode(),
        );
        return sortColumnHeader.getNode();
    }

    /**
     * Creates the tbody as a child of the given table, assigning the node to this.tbody and
     * returning it.
     */
    protected buildTbody(table: HTMLElement) {
        return (this.tbody = Dom.create("tbody", table));
    }

    /**
     * Overriding this function to create custom entries for a table is okay, but it should only be
     * done when it's not possible to accomplish what you need without doing so.  Overriding can
     * help if a table needs more than one kind of entry, as in DatasetSourceTable.
     */
    protected _createEntry(o: OBJ): TableEntry<OBJ, DATA> {
        return new this.entryClass(o, this);
    }

    /**
     * Searches for an index in {@link entries}, which is the objects currently loaded in the table
     * after any pagination is applied. May or may not be the full list of objects.
     */
    _entryIndex(o: OBJ): number {
        if (this.pagination?.lazyLoader) {
            // If this is paginated and lazy loaded, doing a linear search is easier
            // since it doesn't require that we match the comparator on back and front ends.
            for (let i = 0; i < this.entries.length; i++) {
                const entry = this.entries[i];
                if (this.pagination.lazyLoader.equalFunction(o, entry.ebo)) {
                    return i;
                }
            }
            return -1;
        }
        return Arr.binarySearch(this.entries, o, (o, e) => this.fullCompare(o, e.ebo));
    }

    /**
     * Searches for an index in {@link filteredSortedObjects}, which is all objects after applying
     * filtering, even if some are not shown in the table due to pagination.
     */
    _objectListIndex(o: OBJ): number {
        if (this.pagination?.lazyLoader) {
            // If this is paginated and lazy loaded, doing a linear search is easier
            // since it doesn't require that we match the comparator on back and front ends.
            for (let i = 0; i < this.filteredSortedObjects.length; i++) {
                const entry = this.filteredSortedObjects[i];
                if (this.pagination.lazyLoader.equalFunction(o, entry)) {
                    return i;
                }
            }
            return -1;
        }
        return Arr.binarySearch(this.filteredSortedObjects, o, (o, e) => this.fullCompare(o, e));
    }

    /**
     * Whether the given object is in the current pagination batch that's rendered in the table.
     * If the table has no pagination applied, returns if the table's object pool has the object.
     */
    isVisibleInTable(obj: OBJ): boolean {
        return !this.pagination || this._entryIndex(obj) > -1;
    }

    isRenderedInTable(obj: OBJ): boolean {
        const index = this._entryIndex(obj);
        return index >= 0 && this.isIndexRenderedInTable(index);
    }

    isIndexRenderedInTable(index: number): boolean {
        const entry = this.getEntryByRowIndex(index);
        const containerPos = this.maxHtDiv.getBoundingClientRect();
        const entryPos = entry.tr.getBoundingClientRect();

        return entryPos.top >= containerPos.top && entryPos.bottom <= containerPos.bottom;
    }

    _destroyEmpty() {
        if (this._emptyTr) {
            Dom.destroy(this._emptyTr);
            this._emptyTr = null;
        }
    }

    _compareWithFallback(o1: OBJ, o2: OBJ) {
        return this.compare(o1, o2) || Cmp.full(o1, o2);
    }

    // Legacy API: When uses of these methods are removed, we can do some cleanup here (search for
    // "legacy shim").

    /**
     * Constructs an object-dependent row. This is a legacy method; TableEntry.createRow should be
     * preferred for new code.
     */
    constructRow(o: OBJ): HTMLTableRowElement {
        const attrs = this.useHoverClass ? { class: "hover" } : {};
        return Dom.create("tr", attrs);
    }

    /**
     * When defined, a click handler is attached to each entry row that executes this callback. This
     * is a legacy method; TableEntry.onClick should be preferred for new code.
     */
    onClick: (obj: OBJ, row: number, col: number, evt: Event, me: Table<OBJ, DATA>) => void;

    /**
     * These accessor methods made more sense in the old Table model. There generally should be
     * better ways of doing things than operating with raw row and column indexes. In particular,
     * users may often find it easier to iterate over this.entries.
     */
    getCell(row: number, col: number) {
        return this.getRow(row).cells[col];
    }
    getEntryByRowIndex(row: number) {
        return this.entries[row];
    }
    getRow(row: number) {
        return this.entries[row].tr;
    }
    getData(row: number) {
        return this.entries[row].data;
    }

    /**
     * Gets the visible objects in this table.
     * If the table is paginated, this will be a subset of the entire table.
     */
    getObjects() {
        return this.entries.map((entry) => entry.ebo);
    }
    forEachEntry(func: (entry: TableEntry<OBJ, DATA>) => void): void {
        this.entries.forEach((entry) => func(entry));
    }
    addScrollPad(pad: HTMLElement) {
        this._scrollPads.push(pad);
    }
}
Table.prototype.empty = "Nothing here";

module Table {
    export enum InitialLoadState {
        NOT_LOADED, // table has not called handleInitialLoad
        CALLED_INITIAL_LOAD, // set at the START of handleInitialLoad
        FINISHED_INITIAL_LOAD, // set at the END of handleInitialLoad
    }

    abstract class TableSynchronizer {
        protected leftDiv: HTMLElement;
        protected rightDiv: HTMLElement;

        /**
         * Whether to ignore the current scroll event. Since we handle each scroll event by scrolling
         * the other table, we need to ignore the event from scrolling the other table (or else we get
         * an infinite loop).
         */
        private ignoreNextScroll = false;

        constructor(left: HTMLElement, right: HTMLElement) {
            this.setLeftTable(left);
            this.setRightTable(right);
        }

        abstract getLeftScroll(): number;
        abstract getRightScroll(): number;
        abstract setLeftScroll(value: number): void;
        abstract setRightScroll(value: number): void;
        abstract getMaxScroll(): number;

        setLeftTable(table: HTMLElement) {
            this.leftDiv = table;
            dojo_on(this.leftDiv, "scroll", () => {
                this.scrollOtherDiv(
                    this.rightDiv,
                    () => this.getLeftScroll(),
                    (scroll) => this.setRightScroll(scroll),
                );
            });
            Dom.addClass(this.leftDiv, "hide-scrollbar");
        }

        setRightTable(table: HTMLElement) {
            this.rightDiv = table;
            dojo_on(this.rightDiv, "scroll", () => {
                this.scrollOtherDiv(
                    this.leftDiv,
                    () => this.getRightScroll(),
                    (scroll) => this.setLeftScroll(scroll),
                );
            });
        }

        scrollOtherDiv(
            other: HTMLElement,
            getScroll: () => number,
            setOtherScroll: (scroll: number) => void,
        ): void {
            if (this.ignoreNextScroll) {
                this.ignoreNextScroll = false;
                return;
            }
            if (!other) {
                return;
            }
            const rightTop = this.getRightScroll();
            const leftTop = this.getLeftScroll();
            if (rightTop === leftTop) {
                return;
            }
            // If the two sides have unequal height, we need to make sure we don't scroll past where the
            // other side can scroll.
            const currScroll = getScroll();
            const maxScroll = this.getMaxScroll();
            if (currScroll > maxScroll) {
                this.setLeftScroll(maxScroll);
                this.setRightScroll(maxScroll);
                return;
            }
            this.ignoreNextScroll = true;
            setOtherScroll(getScroll());
        }
    }

    /*
     * Synchronizes the vertical scrollbars of two tables and hides the scrollbar of the left table.
     * Can be used to create the illusion of a half-scrollable table, where the right half of the
     * table scrolls horizontally, but the left half is static.
     */
    export class TableSynchronizerVertical extends TableSynchronizer {
        constructor(leftTable: Table<any, any>, rightTable: Table<any, any>) {
            super(leftTable.maxHtDiv, rightTable.maxHtDiv);
        }

        getLeftScroll(): number {
            return this.leftDiv.scrollTop;
        }

        getRightScroll(): number {
            return this.rightDiv.scrollTop;
        }

        setLeftScroll(value: number): void {
            this.leftDiv.scrollTop = value;
        }

        setRightScroll(value: number): void {
            this.rightDiv.scrollTop = value;
        }

        setScroll(value: number): void {
            this.setLeftScroll(value);
            this.setRightScroll(value);
        }

        getMaxScroll(): number {
            const leftMax = this.leftDiv.scrollHeight - this.leftDiv.clientHeight;
            const rightMax = this.rightDiv.scrollHeight - this.rightDiv.clientHeight;
            return Math.min(leftMax, rightMax);
        }
    }

    /*
     * Synchronizes the horizontal scrollbars of a given table and its header.
     * Can be used to allow the platform implementation of Table to have an overflow-able header,
     * letting the header scroll horizontally with the body, while being frozen at the top for vertical
     * scrolls.
     */
    export class TableSynchronizerHorizontal extends TableSynchronizer {
        constructor(table: Table<any, any>) {
            super(table.theadDiv, table.maxHtDiv);
            table.destroyables.push(makeHorizontallyScrollableWithArrows(table.maxHtDiv));
        }

        getLeftScroll(): number {
            return this.leftDiv.scrollLeft;
        }

        getRightScroll(): number {
            return this.rightDiv.scrollLeft;
        }

        setLeftScroll(value: number): void {
            this.leftDiv.scrollLeft = value;
        }

        setRightScroll(value: number): void {
            this.rightDiv.scrollLeft = value;
        }

        getMaxScroll(): number {
            const leftMax = this.leftDiv.scrollWidth - this.leftDiv.clientWidth;
            const rightMax = this.rightDiv.scrollWidth - this.rightDiv.clientWidth;
            return Math.min(leftMax, rightMax);
        }
    }

    export interface TableParams<OBJ extends Base.Object, DATA extends Table.RowData> {
        store: Base.Store<OBJ>;
        entryClass?: new (ebo: OBJ, table: Table<OBJ, DATA>) => TableEntry<OBJ, DATA>;
        parentNode?: string | HTMLElement;
        columns: any[];
        cells?: Table.CellCallback<OBJ, DATA>[];
        header?: Dom.Content[];
        ids?: string[];
        empty?: Dom.Content;
        emptyStyle?: Dom.StyleProps;
        compare?: (o1: OBJ, o2: OBJ) => number;
        filter?: (o: OBJ) => boolean;
        entryIndex?: number;
        scrollPad?: number;
        maxHeight?: string;
        constructRow?: (o: OBJ) => HTMLTableRowElement;
        onClick?: (obj: OBJ, row: number, col: number, evt: Event, me: Table<OBJ, DATA>) => void;
        skipCallout?: boolean;
        calloutDuration?: number;
        calloutColor?: string;
        // Allows the Header to scroll along with the Body in the case that the entire table
        // is wider than the containing div.
        syncHeaderHorizontally?: boolean;
        useHoverClass?: boolean;
        onInitRow?: (tr: HTMLTableRowElement, ebo: OBJ) => void;
        pagination?: Table.Pagination;
        columnFilterModule?: Table.FilterSortModule<OBJ>;
        onFilterChange?: (module: FilterSortModule<OBJ>) => void;
        onShimmerToggle?: (shimmering: boolean) => void;
        onAfterRefresh?: (self: Table<OBJ, DATA>) => void;
        prepopulatedFilteredSortedObjects?: OBJ[];
        // Should be set to TRUE for objects that are not in Base
        // See comment on Table#manuallyResolveObjectUpdates
        manuallyResolveObjectUpdates?: boolean;
        previousFilters?: Map<string, unknown>;
        previousSort?: Sort;
        skipInitialReload?: boolean;
    }

    export interface RowData {
        destroyables: Util.Destroyable[];
    }

    export interface CellCallbackParam<OBJ extends Base.Object, DATA extends RowData> {
        o: OBJ;
        tr: HTMLTableRowElement;
        td: HTMLTableCellElement;
        me: Table<OBJ, DATA>;
        data: DATA;
        firstTime: boolean;
    }

    export interface CellCallback<OBJ extends Base.Object, DATA extends RowData> {
        (p: CellCallbackParam<OBJ, DATA>): Util.Destroyable | void;
    }

    export function addActionButton(
        iconClass: string,
        tooltip: string,
        onclick: () => void,
        actionBar: HTMLElement,
    ) {
        const span = Dom.span({ class: "table-action-button " + iconClass });
        Dom.place(span, actionBar);
        return new ActionNode(span, { tooltip: tooltip, onClick: onclick });
    }

    export interface WidgetParams<O extends Base.Object, D extends RowData>
        extends TableParams<O, D> {
        name?: string | HTMLElement;
        headingSize?: number; // 1-6, default is 2 (for h2 header)
        eboName?: string;
        createNew?: CreateNew;
        createNewButtonLabel?: string;
        closeAll?: () => void;
        closeAllButtonLabel?: string;
    }

    export type CreateNew = (() => void) | CreateNewRow | CreateNewTextBox;

    /**
     * A common Table.Widget createNew convention is to display a row at the end of the table's thead
     * that contains the interface for creating a new item. If createNew implements this interface,
     * creation, hiding, and showing of the row is managed automatically based on the provided
     * information.
     */
    export interface CreateNewRow {
        /**
         * The cells in the row.
         */
        cells: HTMLTableCellElement[];
        /**
         * Called any time the row is displayed anew. Calling hideRow (typically asynchronously)
         * triggers onHide, hides the tr, and destroys any destroyables that are added to d.
         */
        onShow?: (d: Util.Destroyable[], tr: HTMLTableRowElement, hideRow: () => void) => void;
        /**
         * Called when the row is hidden, immediately before destroying the destroyables.
         */
        onHide?: (tr: HTMLTableRowElement) => void;
    }

    /**
     * Building upon CreateNewRow, another common createNew convention is to display an Add button in
     * the last column and a text box that spans the rest of the columns
     */
    interface CreateNewTextBox {
        placeholder: string;
        /**
         * When the user clicks the button (if present) or presses Enter in the text box (when onEnter
         * is not overridden), this function is called with the text box's trimmed value, but only if
         * the trimmed value is not empty.
         *
         * The clearBox parameter can be called to clear the text box, and the hideRow parameter can be
         * used to hide the new row.
         *
         * Unless onSubmit returns true, hideRow is called as soon as onSubmit returns, triggering
         * onHide and the destruction of the destroyables produced in onShow.
         */
        onSubmit: (value: string, clearBox: () => void, hideRow: () => void) => boolean | void;
        /**
         * Creates the cells array; defaults to `Arr.nonNull([txtCell, btnCell])`.
         */
        makeCells?: (
            txtCell: HTMLTableCellElement,
            btnCell?: HTMLTableCellElement,
        ) => HTMLTableCellElement[];
        /**
         * The colSpan of the button's cell; defaults to 1. When 0, the button and its cell are not
         * created.
         *
         * The text box's cell spans all remaining columns after accounting for the button's cell and
         * any custom cells.
         */
        buttonSpan?: number;
        /**
         * The width of the button; defaults to 'half'. See UI.button.
         *
         * Note that this default differs from UI.button's, which is 'one'.
         */
        buttonWidth?: string;
        /**
         * Just like CreateNewRow.onShow, except that the text box and button are also provided.
         */
        onShow?: (
            d: Util.Destroyable[],
            tr: HTMLTableRowElement,
            hideRow: () => void,
            tb: TextBox,
            btn?: Button,
        ) => void;
        /**
         * Called when the user presses Enter in the text box. Defaults to submit.
         */
        onEnter?: (submit: () => void, tb: TextBox, btn?: Button) => void;
    }

    /**
     * When the table is the sole element of one of our widget boxes, this class can be used to
     * construct the entire widget.
     */
    export class Widget<OBJ extends Base.Object, DATA extends RowData> extends Table<OBJ, DATA> {
        // Construction-time properties

        /**
         * The name of the table, which will serve as the content of the heading div. After
         * construction, this.heading's content can be modified to change the widget heading.
         */
        name: string | HTMLElement;

        /**
         * When non-null, this should be a DIV with class="widget". The remaining parts of the widget
         * (e.g., the heading and body elements) will be created during construction.
         *
         * When null, a widget DIV will be constructed automatically, accessible at this.node.
         */
        override parentNode: HTMLElement;

        /**
         * When non-null, a button will be added to the table that allows users to create new objects.
         * When clicked, this callback will fire. After a new EBO is successfully created, Base.set is
         * typically called with the newly-created object.
         */
        createNew: CreateNew;

        createNewButton: Button;

        createNewButtonLabel: string;

        /**
         * When createNew is non-null, this is used in the button's tooltip message. When not provided,
         * this.store.name is used instead. If that is not defined, then no ebo name is used at all.
         */
        eboName: string;

        /**
         * When non-null, a button will be added to the table that allows users to request database
         * deletion in Database settings. When clicked, this callback will fire.
         */
        closeAll?: () => void;

        closeAllButton: Button;

        closeAllButtonLabel?: string;
        // Public API

        /**
         * The DOM node containing the widget name's modifiable content.
         */
        heading: HTMLElement;

        /**
         * The DOM node containing header buttons and icons.
         */
        actionBar: HTMLElement;

        /**
         * The heading size to use (if it exists)
         */
        headingSize: number;

        /**
         * Flag to control whether the add-new row is hidden on blurs
         */
        hideOnBlur = true;

        /**
         * A row to keep highlighted
         */
        highlightedRow: HTMLTableRowElement;

        constructor(params: WidgetParams<OBJ, DATA>) {
            super(params);
        }

        /**
         * Sets the row containing the object to be highlighted.
         * Un-highlights any previously highlighted row.
         */
        setHighlightedRow(obj?: OBJ, scrollToElement = false, then = () => {}): void {
            if (this.highlightedRow) {
                Dom.removeClass(this.highlightedRow, "legacy-table__row--highlighted");
            }
            if (!scrollToElement) {
                this.highlightedRow = obj && this.getEntry(obj)?.tr;
                this.highlightedRow
                    && Dom.addClass(this.highlightedRow, "legacy-table__row--highlighted");
                then();
                return;
            }
            this.scrollToPageWithObject(obj).then((changedPages) => {
                // If we advanced a page, scroll to the element on the new page
                changedPages && this._scrollToElementOnPage(obj);
                this.highlightedRow = obj && this.getEntry(obj)?.tr;
                this.highlightedRow
                    && Dom.addClass(this.highlightedRow, "legacy-table__row--highlighted");
                then();
            });
        }

        /**
         * Promise holds TRUE if we changed pages, or FALSE otherwise.
         */
        scrollToPageWithObject(obj?: OBJ): Promise<boolean> {
            if (obj && this.pagination) {
                // If this is paginated, find the index from the full list of objects instead of just
                // entries (which is only what's currently displayed in the table).
                let index = this._objectListIndex(obj);
                if (index < 0) {
                    // Index is not in object list; this means either there's
                    // an error, or the object in question is filtered out
                    if (this.columnFilterModule) {
                        if (this.isLazyLoading()) {
                            const sort = this.columnFilterModule.getSort();
                            if (!sort) {
                                // Can't get a rank without any sort
                                return Promise.resolve(false);
                            }
                            const filters = this.columnFilterModule.getFilterEntries();
                            return new Promise<boolean>((resolve) => {
                                this.pagination.lazyLoader
                                    .rankFetcher(
                                        obj.id as number,
                                        obj[sort.column],
                                        this.columnFilterModule?.getUniqueSortableValue?.(obj),
                                        sort,
                                        filters,
                                    )
                                    .then((rank) => {
                                        if (rank >= 0) {
                                            this.setPageFromIndex(rank, () => resolve(true));
                                        } else {
                                            resolve(false);
                                        }
                                    });
                            });
                        }

                        this.columnFilterModule.removeAllFilters();
                        return new Promise<boolean>((resolve) => {
                            this.getFilteredAndSortedEntries((results) => {
                                this.filteredSortedObjects = results;
                                index = this._objectListIndex(obj);
                                this.setPageFromIndex(index, () => resolve(true));
                            });
                        });
                    }
                } else if (this.isLazyLoading()) {
                    // If lazy loading and index is in view, we know the current page is the correct one
                    return Promise.resolve(false);
                } else {
                    return new Promise<boolean>((resolve) => {
                        this.setPageFromIndex(index, (pageChanged) => resolve(pageChanged));
                    });
                }
            }
            return Promise.resolve(false);
        }

        private setPageFromIndex(index: number, then?: (pageChanged: boolean) => void): void {
            const page = Math.floor(index / this.pagination.entriesPerPage);
            // Go to the page that this object is on, then let the rest of the code
            // find the position in the page and scroll to it.
            this.setPage(
                page,
                true,
                null,
                (result) => {
                    then?.(result);
                },
                false,
            );
        }

        /**
         * Scrolls the table (if needed) such that the provided object
         * is visible somewhere in the user view.
         */
        scrollToElement(obj: OBJ): void {
            if (!obj) {
                return;
            }

            this.scrollToPageWithObject(obj).then((_ignored) => this._scrollToElementOnPage(obj));
        }

        /**
         * Sets scrolltop to be at the element in question.
         * Element MUST be currently visible in table - e.g. call {@link scrollToPageWithObject} first.
         */
        private _scrollToElementOnPage(obj: OBJ): void {
            if (!obj) {
                return;
            }
            const index = this._entryIndex(obj);
            if (index < 0 || this.isIndexRenderedInTable(index)) {
                // the entry is either not in the table's current entries
                // or is already in view; nothing more to be done here
                return;
            }
            const entry = this.getEntryByRowIndex(index);
            this.setScrollTop(index * entry.tr.clientHeight);
        }

        protected override buildDom() {
            let widgetNode = this.parentNode;
            const table = this.buildTable();
            if (!widgetNode) {
                this.node = widgetNode = Dom.div();
            } else {
                this.node = table;
            }
            this.actionBar = Dom.create("div", { class: "action-bar" }, widgetNode, "first");
            if (this.name) {
                if (this.name instanceof HTMLHeadingElement) {
                    this.heading = Dom.place(this.name, widgetNode);
                } else {
                    const hSize = this.headingSize || "2";
                    this.heading = Dom.create(
                        "h" + hSize,
                        {
                            class: "table-header-h" + hSize,
                            content: this.name,
                        },
                        widgetNode,
                    );
                    // Kind of a hack, but when we use a h1 we want some extra margin to get this action
                    // bar to line up.
                    Dom.addClass(this.actionBar, `h${this.headingSize}-action-bar`);
                }
                Dom.addClass(this.heading, "table-widget--header");
            }
            this.tableWrapper = Dom.div({ class: "table-div-container" }, table);
            Dom.place(this.tableWrapper, widgetNode);
            // This call must occur after buildTable so that thead is defined.
            if (this.closeAll) {
                this.destroyables.push(
                    (this.closeAllButton = new Button({
                        class: "unsafe skinny",
                        label: this.closeAllButtonLabel,
                        width: "default",
                        onClick: this.closeAll,
                        parent: this.actionBar,
                    })),
                );
            }
            this.constructCreateNewButton();
        }
        protected constructCreateNewButton() {
            if (this.createNew) {
                let itemName;
                if (Is.defined(this.eboName)) {
                    itemName = this.eboName.toLowerCase();
                } else if (Is.defined(this.store.name)) {
                    itemName = this.store.name.toLowerCase();
                } else {
                    itemName = "item";
                }
                this.destroyables.push(
                    (this.createNewButton = Button.newAddition({
                        label: this.createNewButtonLabel || "Add new " + itemName,
                        onClick: this.toCreateNewOnClick(this.createNew),
                        parent: this.actionBar,
                        makeFocusable: true,
                    })),
                );
            }
        }
        private toCreateNewOnClick(cn: any): () => void {
            // cn is a CreateNew, but making it <any> saves a bunch of casts in the first few lines.
            if (typeof cn === "function") {
                return cn;
            }
            const cnr: CreateNewRow = cn.cells ? cn : this.toCreateNewRow(cn);
            const tr = (this.tbody as HTMLTableElement).insertRow();
            Dom.place(cnr.cells, tr);
            const destroyables: Util.Destroyable[] = [];
            return () => {
                if (!Dom.isHidden(tr)) {
                    // If it's already showing, createNew resets the row by hiding and showing it again.
                    hideNewRow();
                }
                Dom.show(tr);
                if (cnr.onShow) {
                    cnr.onShow(destroyables, tr, hideNewRow);
                }
            };
            function hideNewRow() {
                if (Dom.isHidden(tr)) {
                    return;
                }
                cnr.onHide && cnr.onHide(tr);
                Util.destroy(destroyables);
                destroyables.length = 0;
                Dom.hide(tr);
            }
        }

        private toCreateNewRow(cntb: CreateNewTextBox): CreateNewRow {
            const btnSpan = Is.defined(cntb.buttonSpan) ? cntb.buttonSpan : 1;
            const txtCell = Dom.td({ colSpan: this.columns.length - btnSpan });
            let btnCell: HTMLTableCellElement;
            if (btnSpan > 0) {
                btnCell = Dom.td({
                    colSpan: btnSpan,
                    class: "centered",
                });
            }
            let cells = Arr.filterNonNullish([txtCell, btnCell]);
            if (cntb.makeCells) {
                cells = cntb.makeCells(txtCell, btnCell);
                // Update the text cell's colSpan to use the columns not used by the rest of the cells.
                txtCell.colSpan =
                    this.columns.length
                    - cells.reduce((n, cell) => {
                        return n + (cell === txtCell ? 0 : cell.colSpan);
                    }, 0);
            }
            let captureHideRow: () => void;
            return {
                cells: cells,
                onShow: (d, tr, hideRow) => {
                    if (!captureHideRow) {
                        // This is the first call to onShow; attach a focus container to hide the row
                        // when tr is blurred. We cannot recreate this each onShow because destroying it
                        // trashes tr (removes it from the DOM, destroys its children, etc.).
                        this.destroyables.push(
                            new UiWidget.DijitFocusContainer({
                                domNode: tr,
                                onBlur: () => {
                                    if (this.hideOnBlur) {
                                        captureHideRow();
                                    }
                                },
                            }),
                        );
                    }
                    captureHideRow = hideRow;
                    const tb = Dom.place(
                        new TextBox({
                            placeholder: cntb.placeholder,
                        }),
                        txtCell,
                        "only",
                    );
                    let btn: Button;
                    tb.onSubmit = cntb.onEnter
                        ? () => {
                              cntb.onEnter(submit, tb, btn);
                          }
                        : submit;
                    d.push(tb);
                    if (btnCell) {
                        d.push(
                            (btn = Dom.place(
                                new Button({
                                    label: "Add",
                                    onClick: submit,
                                    class: "safe important skinny",
                                    width: cntb.buttonWidth || "half",
                                }),
                                btnCell,
                                "only",
                            )),
                        );
                    }
                    if (cntb.onShow) {
                        cntb.onShow(d, tr, hideRow, tb, btn);
                    }
                    tb.focus();
                    function submit() {
                        const val = tb.getValue().trim();
                        if (val) {
                            if (
                                !cntb.onSubmit(
                                    val,
                                    () => {
                                        tb.clear();
                                    },
                                    hideRow,
                                )
                            ) {
                                hideRow();
                            }
                        } else {
                            tb.focus();
                        }
                    }
                },
            };
        }
    }

    export interface Pagination {
        curPage: number;
        previousPage?: number;
        entriesPerPage: number;
        onPageChange?: (page: number) => void;
        /**
         * Provides a mechanism for lazy-loading, meaning that the table will fetch
         * entries from the back-end as needed.
         * undefined if this table does not lazy-load.
         */
        lazyLoader?: LazyLoader;
        /**
         * Additional sass class to apply to the pagination bar
         */
        additionalClass?: string;
    }

    export interface LazyLoader {
        initialSelected: Filtering.Selection;
        fetcher: (filterParams: Filtering.Params) => Promise<Filtering.Result>;
        rankFetcher: (
            id: number,
            value: string,
            secondaryValue: string | undefined,
            sort: Filtering.Sort,
            filters: Filtering.Entry[],
        ) => Promise<number>;
        equalFunction: (a, b) => boolean;
        onInitialLoad?: (total: number) => void;
        // If provided and evaluated to true, will display an empty table without fetching
        shouldShowEmptyResults?: () => boolean;
        // tracks total number of entries represented by this lazy loading module;
        // is updated automatically after fetcher is called.
        totalEntries?: number;
    }

    export interface SelectableWidgetParams<O extends Base.Object, D extends RowData>
        extends WidgetParams<O, D> {
        onCheckboxChange?: (checkbox: Checkbox) => void;
        // Should return a string in the failure case and null for the success case.
        // Disable checkbox selection for the cells that fail, and display a tooltip with
        // the returned string as the tooltip copy.
        checkboxFilter?: (obj: O) => string | null;
    }

    export class SelectableWidget<
        OBJ extends Base.Object,
        ID extends string | number,
        DATA extends RowData,
    > extends Widget<OBJ, DATA> {
        private parentCheckbox: TripleCheckbox;
        private rowCheckboxes: Map<ID, Checkbox>;
        /** Checkboxes that failed the {@link checkboxFilter} mapped to their respective obj IDs. */
        private disabledCheckboxes: Map<Checkbox, ID>;
        private selected: Set<ID>;

        /**
         * Stores the last state (within CHECKED or UNCHECKED) of the parent checkbox, so that
         * when the checkbox is in the indeterminate state, we can set `allSelected` of a
         * `Filtering.Selection` properly.
         */
        private previousParentCheckboxState:
            | TripleCheckbox.CheckboxState.UNCHECKED
            | TripleCheckbox.CheckboxState.CHECKED = TripleCheckbox.CheckboxState.UNCHECKED;
        private onCheckboxChange: (checkbox: Checkbox) => void;
        private checkboxFilter?: (o: OBJ) => string | null;

        constructor(params: SelectableWidgetParams<OBJ, DATA>) {
            super(params);
            Object.assign(this, params);
        }

        protected override onInitialLoad(): void {
            super.onInitialLoad();
            if (this.isLazyLoading()) {
                const iselection = this.pagination.lazyLoader.initialSelected;
                if (!Filtering.isEmptySelection(iselection)) {
                    this._setSelectionDontReload(iselection, true);
                }
            }
        }

        protected override onDestroy(entry: TableEntry<OBJ, DATA>) {
            // Clean up rowCheckboxes when the parent table refreshes
            // since they get destroyed and need to be re-created once
            // scrolled back into view
            const checkbox = this.rowCheckboxes.get(entry.ebo.id as ID);
            if (checkbox) {
                this.rowCheckboxes.delete(entry.ebo.id as ID);
                this.disabledCheckboxes.delete(checkbox);
                Dom.remove(checkbox.getNode());
                Util.destroy(checkbox);
            }
        }

        override destroy() {
            super.destroy();
            this.rowCheckboxes.forEach((cb) => Util.destroy(cb));
            this.rowCheckboxes.clear();
            this.disabledCheckboxes.clear();
        }

        /**
         * @param filteredSortedObjs if null and the table is lazy-loading, this will cause a fetch operation.
         */
        override updateFilter(
            filteredSortedObjs?: OBJ[],
            totalEntries?: number,
            then?: () => void,
        ): void {
            if (
                this.parentCheckbox.state === TripleCheckbox.CheckboxState.INDETERMINATE
                || this.parentCheckbox.state === TripleCheckbox.CheckboxState.UNCHECKED
            ) {
                // If we're in the intermediate checkbox state, deselect everything
                // (This matches the results table behavior)
                this.setParentState(TripleCheckbox.CheckboxState.UNCHECKED, true);
                // Update checkboxes in view to be unchecked
                this.rowCheckboxes.forEach((checkbox) => this.setCheckbox(checkbox, false, true));
            }
            super.updateFilter(filteredSortedObjs, totalEntries, then);
        }

        getToggledSet(): Set<ID> {
            return new Set(this.selected);
        }

        getToggledArray(): ID[] {
            return [...this.selected];
        }

        /**
         * Resolves allSelected and negated lists into a single ID array using
         * the 'all' param as the basis for "all selected". 'all' is ignored if
         * not lazy loading.
         */
        resolveSelectedIdArray(all: { forEach: (callback: (id: ID) => void) => void }): ID[] {
            if (!this.isLazyLoading()) {
                return [...this.selected];
            }
            const set: Set<ID> = new Set();
            if (this.isAllSelected()) {
                all.forEach((id) => set.add(id));
                this.selected.forEach((selectedId) => set.delete(selectedId));
            } else {
                this.selected.forEach((selectedId) => set.add(selectedId));
            }
            return [...set];
        }

        isAllSelected(): boolean {
            return this.parentCheckbox.state === TripleCheckbox.CheckboxState.CHECKED;
        }

        setAllSelected(): void {
            this.setParentState(TripleCheckbox.CheckboxState.CHECKED);
        }

        setNoneSelected(): void {
            this.setParentState(TripleCheckbox.CheckboxState.UNCHECKED);
        }

        /**
         * Deletes the given object id from the `selected` set.
         * Note that this action can have different meanings depending on {@link #previousParentCheckboxState}:
         * If the previous state is UNCHECKED, `selected` represents selected objects
         * If the previous state is CHECKED, all objects are assumed to be selected, EXCEPT the ones in `selected`
         * When a given object is no longer in the table, i.e. it has been deleted, calling this function will
         * properly restore the table state to ignore/exclude the deleted object.
         */
        removeFromSelectedSet(objId: ID): void {
            this.selected.delete(objId);
        }

        private setParentState(
            state: TripleCheckbox.CheckboxState.CHECKED | TripleCheckbox.CheckboxState.UNCHECKED,
            silent = false,
        ): void {
            this.parentCheckbox.setState(state, silent);
            this.previousParentCheckboxState = state;
            this.selected.clear();
        }

        getSelection(): Filtering.Selection {
            const allSelected =
                this.previousParentCheckboxState === TripleCheckbox.CheckboxState.CHECKED;
            const negated = [...this.selected] as number[];
            // Add disabledCheckbox id's to negated if allSelected is true to avoid including
            // the disabled custodians in our selection.
            if (allSelected) {
                this.disabledCheckboxes.forEach((id) => negated.push(id as number));
            }

            return {
                allSelected,
                negated,
                filters: this.columnFilterModule?.getFilterEntries() ?? [],
                count: this.getSelectedCount(),
            };
        }

        getTableSelection(): Filtering.TableSelection {
            const selection = this.getSelection();
            const tableData = {
                sort: this.columnFilterModule?.getRawSort(),
                rawFilters: this.columnFilterModule?.getRawFilters() ?? new Map(),
                page: this.pagination?.curPage ?? 0,
                scrollTop: this.getScrollTop(),
            };
            return Object.assign(tableData, selection);
        }

        private _setSelectionDontReload(
            selectionOrTableSelection: Filtering.Selection,
            silent = false,
        ): void {
            const selection = toDefaultTableSelection(selectionOrTableSelection);
            this.selected.clear();
            if (this.columnFilterModule) {
                this.columnFilterModule.removeAllFilters();
                this.columnFilterModule.setFilters(selection.rawFilters);
                defined(selection.sort) && this.columnFilterModule.setSort(selection.sort);
                if (this.pagination) {
                    this.pagination.curPage = selection.page;
                    this.pagination.previousPage = undefined;
                }
            }
            if (!this.isLazyLoading()) {
                // Not lazy loading, so we can handle this more simply assuming filteredSortedObjects
                // contains every single object we possibly can view
                if (selection.allSelected) {
                    this.filteredSortedObjects.forEach((obj) =>
                        this.selected.add(obj.getKey() as ID),
                    );
                    selection.negated.forEach((toggled) => this.selected.delete(toggled as ID));
                } else {
                    selection.negated.forEach((toggled) => this.selected.add(toggled as ID));
                }
                this.updateParentCheckbox(silent);
                return;
            }
            // Manually handle visible checkboxes if we're lazy loading
            // selection.negated becomes our new selected
            selection.negated.forEach((id) => this.selected.add(id as ID));
            // If NOT selection.allSelected, the ones in negated mean they ARE selected,
            // so toggle to CHECKED iff NOT allSelected
            this.rowCheckboxes.forEach((checkbox, id) =>
                this.setCheckbox(
                    checkbox,
                    selection.allSelected ? !this.selected.has(id) : this.selected.has(id),
                    true,
                ),
            );
            // Set the previous state to the current allSelected status:
            // this is necessary for future calls to getSelectedCount
            this.previousParentCheckboxState = selection.allSelected
                ? TripleCheckbox.CheckboxState.CHECKED
                : TripleCheckbox.CheckboxState.UNCHECKED;

            const total = this.getTotalEntries() || selection.count;
            // Manually calculate selected count - this.getSelectedCount() relies on the parent
            // checkbox state that we're about to set here
            const selected = selection.allSelected
                ? total - this.selected.size
                : this.selected.size;
            const newParentState =
                selected === 0
                    ? TripleCheckbox.CheckboxState.UNCHECKED
                    : selected === total
                      ? TripleCheckbox.CheckboxState.CHECKED
                      : TripleCheckbox.CheckboxState.INDETERMINATE;
            this.parentCheckbox.setState(newParentState, silent);
            defined(selection.scrollTop) && this.setScrollTop(selection.scrollTop);
        }

        /**
         * Replaces the current selection with the provided one, clearing all filters in the process
         */
        setSelection(selection: Filtering.Selection, then?: () => void, reload = true): void {
            this._setSelectionDontReload(selection, true);
            if (reload) {
                this.reload(undefined, () => {
                    this.onFilterChange?.(this.columnFilterModule);
                    then?.();
                });
            } else {
                then?.();
            }
        }

        getSelectedCount(): number {
            if (this.isAllSelected()) {
                return this.getTotalEntries() - this.disabledCheckboxes.size;
            }
            if (this.parentCheckbox.state === TripleCheckbox.CheckboxState.UNCHECKED) {
                return 0;
            }
            if (this.previousParentCheckboxState === TripleCheckbox.CheckboxState.UNCHECKED) {
                return this.selected.size;
            }
            return this.getTotalEntries() - (this.selected.size + this.disabledCheckboxes.size);
        }

        setSelected(toSelect: { forEach: (callback: (o: OBJ) => void) => void }, silent: boolean) {
            const toSelectSet = new Set<ID>();
            toSelect.forEach((obj) => toSelectSet.add(obj.getKey() as ID));

            this.selected.clear();
            // Add all selection to selected
            toSelectSet.forEach((id) => {
                this.selected.add(id);
            });
            // Update checkboxes
            this.rowCheckboxes.forEach((checkbox, id) => {
                this.setCheckbox(checkbox, toSelectSet.has(id), silent);
            });
            this.updateParentCheckbox();
        }

        delete(toDelete: { forEach: (callback: (o: OBJ) => void) => void }) {
            // remove attachment between objects and checkboxes
            toDelete.forEach((obj) => {
                const id = obj.getKey() as ID;
                const checkbox = this.rowCheckboxes.get(id);
                if (checkbox) {
                    this.rowCheckboxes.delete(id);
                    this.disabledCheckboxes.delete(checkbox);
                    checkbox.destroy();
                }
                this.selected.delete(id);
            });
            this.updateParentCheckbox();
        }

        /** Sets the checkbox iff the checkbox is not disabled. */
        private setCheckbox(checkbox: Checkbox, state: boolean, silent: boolean) {
            if (this.disabledCheckboxes.has(checkbox)) {
                return;
            }

            checkbox.set(state, silent);
        }

        private updateParentCheckbox(silent = true) {
            if (this.isLazyLoading()) {
                // Special handling for the lazy-loading case, since we won't have all ids in memory;
                // we track selections using a "select all" flag and this.selected as a list of ids that
                // represent "negated". E.g. if "select all", the list of ids represent ones we manually
                // unselected. If not "select all", the list of ids represent ones we manually selected.
                if (
                    this.previousParentCheckboxState === TripleCheckbox.CheckboxState.UNCHECKED
                    || this.previousParentCheckboxState === TripleCheckbox.CheckboxState.CHECKED
                ) {
                    const oppositeState =
                        this.previousParentCheckboxState === TripleCheckbox.CheckboxState.UNCHECKED
                            ? TripleCheckbox.CheckboxState.CHECKED
                            : TripleCheckbox.CheckboxState.UNCHECKED;

                    if (this.selected.size === this.getTotalEntries()) {
                        // We have manually either selected or unselected every checkbox, thereby
                        // fulfilling the opposite state from where we started
                        this.parentCheckbox.setState(oppositeState, true);
                        // Flip to the other tracking mode...
                        this.selected.clear();
                        this.previousParentCheckboxState = oppositeState;
                    } else {
                        // If we have any selections, toggle to indeterminate state. Otherwise, if
                        // we have not selected or unselected any checkboxes, revert to the previous
                        // state
                        this.parentCheckbox.setState(
                            this.selected.size
                                ? TripleCheckbox.CheckboxState.INDETERMINATE
                                : this.previousParentCheckboxState,
                            silent,
                        );
                    }
                }
                return;
            }
            if (this.selected.size) {
                this.parentCheckbox.setState(
                    this.selected.size < this.filteredSortedObjects.length
                        ? TripleCheckbox.CheckboxState.INDETERMINATE
                        : TripleCheckbox.CheckboxState.CHECKED,
                    silent,
                );
            } else {
                this.parentCheckbox.setState(TripleCheckbox.CheckboxState.UNCHECKED, silent);
            }
        }

        override buildDom() {
            this.rowCheckboxes = new Map();
            this.disabledCheckboxes = new Map();
            this.selected = new Set();
            this.parentCheckbox = new TripleCheckbox(
                {},
                (oldState, newState) => {
                    if (newState === TripleCheckbox.CheckboxState.INDETERMINATE) {
                        if (oldState !== TripleCheckbox.CheckboxState.INDETERMINATE) {
                            this.previousParentCheckboxState = oldState;
                        }
                        return;
                    }

                    this.previousParentCheckboxState = newState;
                    if (this.isLazyLoading()) {
                        this.selected.clear();
                    } else {
                        if (newState === TripleCheckbox.CheckboxState.UNCHECKED) {
                            this.selected.clear();
                        } else if (newState === TripleCheckbox.CheckboxState.CHECKED) {
                            this.filteredSortedObjects
                                .filter((o) => !this.checkboxFilter?.(o))
                                .forEach((o) => this.selected.add(o.getKey() as ID));
                        }
                    }
                    const checked = newState === TripleCheckbox.CheckboxState.CHECKED;
                    this.rowCheckboxes.forEach((checkbox) =>
                        this.setCheckbox(checkbox, checked, true),
                    );
                    this.onCheckboxChange?.(null);
                },
                true,
                false,
            );

            this.destroyables.push(this.parentCheckbox);

            // Insert a checkbox at the start of each row
            this.columns.unshift({});
            this.header.unshift(Dom.node(this.parentCheckbox));
            this.cells.unshift((p) => {
                const id = p.o.getKey() as ID;
                if (!this.rowCheckboxes.has(id)) {
                    // generate a new checkbox and add to various data structures
                    // if checkbox filter fails (returns string), don't allow selection.
                    // otherwise, if the "select all" checkbox is checked and we are lazy loading,
                    // then `selected` represents all the entries that we manually unselected
                    const disableCheckboxReason = this.checkboxFilter?.(p.o);
                    const shouldBeSelected =
                        !disableCheckboxReason
                        && this.previousParentCheckboxState === CheckboxState.CHECKED
                        && this.isLazyLoading()
                            ? !this.selected.has(id)
                            : this.selected.has(id);
                    const checkbox = new Checkbox({
                        parent: p.td,
                        state: shouldBeSelected,
                        disabled: !!disableCheckboxReason,
                        disabledReason: disableCheckboxReason,
                        onChange: (checked) => {
                            // If we're lazy loading, then we need to add to selected only iff the new state
                            // is different from the previous state of the master checkbox. E.g. if we
                            // started with everything checked, then we only add to `selected` if we have
                            // unselected a minor checkbox, and vice versa.
                            const shouldAddToNegated = this.isLazyLoading()
                                ? (this.previousParentCheckboxState
                                      === TripleCheckbox.CheckboxState.UNCHECKED)
                                  === checked
                                : checked;
                            if (shouldAddToNegated) {
                                this.selected.add(id);
                            } else {
                                this.selected.delete(id);
                            }

                            // handle the parent checkbox appropriately
                            this.updateParentCheckbox();
                            this.onCheckboxChange && this.onCheckboxChange(checkbox);
                        },
                    });
                    this.rowCheckboxes.set(id, checkbox);
                    if (disableCheckboxReason) {
                        this.disabledCheckboxes.set(checkbox, id);
                    }
                }
            });
            super.buildDom();
        }
    }

    export const Entry = TableEntry;
    export type Entry<O extends Base.Object, D extends RowData> = TableEntry<O, D>;

    export function createScrollPad(
        cellMaker: Dom.TagFunction<HTMLTableCellElement>,
        tr: HTMLElement,
    ): HTMLTableCellElement {
        return Dom.place(
            cellMaker({
                class: "table-scroll-pad",
                style: {
                    width: `${SCROLLBAR_WIDTH}px`,
                },
                content: E.NBSP,
            }),
            tr,
        );
    }

    /**
     * Most tables show rows of EBOs. Sometimes, we want to be able to click on those rows in order to
     * be able to see an expanded description, possibly interactive, of the clicked EBO. This
     * encapsulates that pattern. Extend this class and define the `onShowDetail` and `onHideDetail`
     * methods as appropriate, then pass it as the `entryClass` parameter when creating a new Table.
     */
    export abstract class DetailEntry<
        OBJ extends Base.Object,
        DATA extends RowData,
    > extends TableEntry<OBJ, DATA> {
        showingDetail = false;

        // The row and cell containing the expanded details for this entry.
        detailRow: HTMLTableRowElement;
        detailCell: HTMLElement;

        protected onShowDetail(div: HTMLElement) {}
        protected onHideDetail(div: HTMLElement) {}

        protected detailCellClass(): string {
            return "detail-cell";
        }

        override createRows() {
            this.detailRow = Dom.tr({ class: "hidden" });
            this.detailCell = Dom.create(
                "div",
                Dom.create(
                    "td",
                    {
                        class: this.detailCellClass(),
                        colSpan: this.table.columns.length,
                    },
                    this.detailRow,
                ),
            );
            const mainRow = this.createRow();
            Dom.addClass(mainRow, "main-row");
            return [mainRow, this.detailRow];
        }
        showDetail(show = true) {
            if (show) {
                if (!this.showingDetail) {
                    this.showingDetail = true;
                    this.onShowDetail(this.detailCell);
                }
            } else if (this.showingDetail) {
                this.showingDetail = false;
                this.onHideDetail(this.detailCell);
            }
            Dom.toggleClass(this.rows, "detail-active", this.showingDetail);
            Dom.show(this.detailRow, this.showingDetail);
        }
        hideDetail() {
            this.showDetail(false);
        }
        override onClick() {
            this.showDetail(!this.showingDetail);
        }
    }

    export class LegacyTableEntry<
        O extends Base.Object,
        D extends Table.RowData,
    > extends TableEntry<O, D> {
        override initCells() {
            if (this.cells) {
                return;
            }
            this.cells = this.table.cells.map((cell) => {
                return (td, isNew) =>
                    cell({
                        o: this.ebo,
                        tr: this.tr,
                        td: td,
                        me: this.table,
                        data: this.data,
                        firstTime: !!isNew,
                    });
            });
        }
    }

    /**
     * Represents a box that shows a filter selected from the table, as shown here:
     * https://www.figma.com/file/MjjzLKBFhiGxCEKfoB5RyR/%5BEP-1555%5D-Filtering-and-lazy-loading-for-custodian-table?type=design&node-id=2008-15448&mode=design&t=ZSVnB9DWn1riueUF-4
     */
    export class FilterDisplayEntry<FILTERDATA> {
        node: HTMLElement;
        private filterText: HTMLElement;
        editButton: Icon;

        private toDestroy: Util.Destroyable[] = [];

        constructor(private filter: Filter<any, FILTERDATA>) {
            this.editButton = new Icon.ActionIcon("pencil-20", {
                onClick: () => {
                    this.filter.scrollToAndOpenPopover();
                },
            });

            this.node = Dom.div(
                { class: "filter-display-entry" },
                Dom.div(
                    Dom.span({ class: "semi-bold" }, `${filter.definition.column}: `),
                    (this.filterText = Dom.span(``)),
                ),
                this.editButton.node,
            );

            this.toDestroy.push(this.editButton, new Tooltip(this.editButton, "Edit filter"));
        }

        setHighlighted(highlighted: boolean) {
            if (highlighted) {
                Dom.addClass(this.node, "editing");
                this.editButton.setIconClass("pencil-white-20");
            } else {
                Dom.removeClass(this.node, "editing");
                this.editButton.setIconClass("pencil-20");
            }
        }

        setFilterValue(filterData: FILTERDATA): void {
            this.filterText.textContent = `"${this.filter.getOrCreateFilterPopover().toFilterDisplay(filterData)}"`;
        }

        destroy(): void {
            Util.destroy(this.toDestroy);
        }
    }

    /**
     * Handles {@link FilterDisplayEntry} listings for a table, and includes a "clear all" button.
     */
    export class FilterDisplayBar<OBJ extends Base.Object> {
        private node: HTMLElement;
        private filterSection: HTMLElement;
        // Map of columns to the boxes that show the filter for each entry
        private boxes: Map<string, FilterDisplayEntry<unknown>>;

        private destroyables: Util.Destroyable[] = [];

        constructor(
            private filterSortModule: Table.FilterSortModule<OBJ>,
            updateFilter: () => void,
        ) {
            this.boxes = new Map();
            this.filterSection = Dom.div({ class: "filter-display-bar__filter-section" });
            const removeFilters = Dom.a(
                {
                    class: "everblue-link remove-filter-text",
                    onclick: () => {
                        this.hide();
                        this.filterSortModule.removeAllFilters();
                        updateFilter();
                    },
                },
                "Remove filters",
            );
            this.node = Dom.div({ class: "filter-display-bar" }, this.filterSection, removeFilters);
            this.hide();
        }

        hide(): void {
            Dom.hide(this.node);
        }

        show(): void {
            Dom.show(this.node);
        }

        setHighlighted(column: string, highlighted: boolean): void {
            this.boxes.get(column)?.setHighlighted(highlighted);
        }

        addFilter(column: string, value: unknown): void {
            let displayEntry = this.boxes.get(column);
            const filter = this.filterSortModule.getColumnFilter(column);
            if (!displayEntry) {
                displayEntry = new Table.FilterDisplayEntry(filter as Filter<OBJ, unknown>);
                Dom.place(displayEntry.node, this.filterSection);
                this.destroyables.push(displayEntry);
                this.boxes.set(column, displayEntry);
            }
            displayEntry.setFilterValue(value);
            this.show();
        }

        removeFilter(column: string): void {
            const displayEntry = this.boxes.get(column);
            this.boxes.delete(column);
            if (displayEntry) {
                Dom.remove(displayEntry.node);
                displayEntry.destroy();
            }
            if (!this.boxes.size) {
                this.hide();
            }
        }

        removeAll(): void {
            this.boxes.forEach((entry) => entry.destroy());
            this.boxes.clear();
            Dom.empty(this.filterSection);
            this.hide();
        }

        getNode(): HTMLElement {
            return this.node;
        }

        destroy(): void {
            Util.destroy(this.destroyables);
        }
    }

    export interface FilterPopoverType<FILTERDATA> {
        new (
            filter: Filter<any, FILTERDATA>,
            parent: HTMLElement,
            onClose?: () => void,
        ): FilterPopover<FILTERDATA>;
    }

    /**
     * Popover balloon that appears to allow the user to set filter params.
     */
    export abstract class FilterPopover<FILTERDATA> {
        popover: Popover;
        protected trashIcon: Button.IconButton;
        protected addFilterButton: Button;
        protected clearButton: HTMLAnchorElement;

        node: HTMLElement;

        protected toDestroy: Util.Destroyable[] = [];

        constructor(
            protected filter: Filter<any, FILTERDATA>,
            parent: HTMLElement,
        ) {
            this.popover = new Popover(
                {
                    dialogContent: this.createPopoverContent(),
                    // Need to update the popover on preOpen rather than onOpen to prevent flickering
                    preOpen: () => this.onPreOpen(),
                    onClose: () => this.setFilterBarHighlighted(false),
                    orient: ["below", "below-centered"],
                },
                parent,
            );
            const filterFocusDiv = makeFocusable(parent, "focus-with-space-style");
            this.toDestroy.push(filterFocusDiv, this.popover);
            this.node = this.popover.getNode();
            this.postConstruct();
        }

        protected postConstruct(): void {}

        protected abstract createFilterTextboxContent(): HTMLElement;
        protected abstract getValue(): FILTERDATA;
        protected abstract updateDisplay(data: FILTERDATA): void;
        protected abstract initialFocus(): void;

        abstract hasInputValue(): boolean;
        abstract clearInputs(): void;
        abstract toFilterDisplay(data: FILTERDATA): string;

        private createPopoverContent(): HTMLElement {
            const termTextboxContainer = this.createFilterTextboxContent();
            const header = Dom.div(
                { class: "filter-tooltip-dialog__header" },
                Dom.div({ class: "semi-bold" }, `Filter by ${this.filter.definition.column}`),
                (this.clearButton = Dom.a(
                    {
                        class: "text-action disabled",
                        onclick: () => {
                            this.filter.clearInputs();
                            this.onInputChange();
                        },
                    },
                    "Clear",
                )),
            );
            this.trashIcon = new Button.IconButton({
                iconClass: "trash-20",
                onClick: () => {
                    this.filter.updateFilter();
                    this.popover.close();
                },
                tooltip: "Remove filter",
            });
            const cancelButton = new Button({
                class: "skinny",
                width: "96px",
                label: "Cancel",
                onClick: () => this.popover.close(),
                makeFocusable: true,
            });
            this.addFilterButton = new Button({
                class: "safe important skinny",
                width: "96px",
                label: "Add",
                onClick: () => {
                    this.filter.updateFilter(this.getValue());
                    this.popover.close();
                },
                makeFocusable: true,
                disabled: true,
            });

            this.toDestroy.push(this.trashIcon, cancelButton, this.addFilterButton);
            return Dom.div(
                { class: "filter-tooltip-dialog" },
                header,
                termTextboxContainer,
                Dom.hr(),
                Dom.div(
                    { class: "filter-tooltip-dialog__footer" },
                    Dom.div({ class: "trash-icon" }, this.trashIcon.node),
                    Dom.div(
                        { class: "filter-tooltip-dialog__buttons" },
                        cancelButton.node,
                        this.addFilterButton.node,
                    ),
                ),
            );
        }

        onPreOpen(): void {
            const curValue = this.filter.getFilterValue();
            this.updateDisplay(curValue);
            this.addFilterButton.setContent(curValue ? "Save" : "Add");
            this.trashIcon.setDisabled(!curValue);
            this.addFilterButton.setDisabled(true);
        }

        onInputChange(): void {
            const hasValue = this.filter.hasInputValue();
            Dom.toggleClass(this.clearButton, "disabled", !hasValue);
            this.addFilterButton.setDisabled(!hasValue);
        }

        openAndFocus(): void {
            this.popover.open();
            this.focus();
        }

        focus(): void {
            // Comment taken from StorybuilderTimelineTab.ts:
            // Annoying hack for focus issues... just calling this.termTextbox.focus() doesn't guarantee
            // that the textbox will actually focus because it's in a tooltip dialog, apparently.
            setTimeout(() => {
                this.initialFocus();
                this.setFilterBarHighlighted(true);
            }, 5);
        }

        private setFilterBarHighlighted(highlighted: boolean): void {
            this.filter.module.filterDisplayBar.setHighlighted(
                this.filter.definition.column,
                highlighted,
            );
        }

        destroy(): void {
            Util.destroy(this.toDestroy);
        }
    }

    export class StringFilterPopover extends FilterPopover<string> {
        private termTextbox: Validated.Text;

        protected createFilterTextboxContent(): HTMLElement {
            this.termTextbox = new Validated.Text({
                name: "value",
                onSubmit: (val: string) => {
                    this.filter.updateFilter(val);
                    this.popover.close();
                },
                onChange: (val: string) => {
                    this.onInputChange();
                    const curFilterValue = this.filter.getFilterValue();
                    this.addFilterButton.setDisabled(
                        (!val && !curFilterValue) || val === curFilterValue,
                    );
                },
            });
            this.toDestroy.push(this.termTextbox);

            return Dom.div(
                { class: "filter-tooltip-dialog__term-textbox-container" },
                Dom.node(this.termTextbox),
            );
        }

        protected getValue(): string {
            return this.termTextbox.getValue();
        }

        protected initialFocus(): void {
            this.termTextbox.focus();
        }

        protected updateDisplay(display: string): void {
            this.termTextbox.setValue(display);
        }

        toFilterDisplay(data: string): string {
            return data;
        }

        hasInputValue(): boolean {
            return !!this.termTextbox.getValue();
        }

        clearInputs(): void {
            this.termTextbox.setValue(null);
        }
    }

    export class DateFilterPopover extends FilterPopover<DateRange> {
        private fromDatebox: DateBox;
        private toDatebox: DateBox;
        private errorContents: HTMLElement;
        private errorContainer: HTMLElement;

        protected createFilterTextboxContent(): HTMLElement {
            this.errorContents = Dom.div({ class: "validated-error-content" });
            const errorIcon = new Icon("alert-triangle-red-20 validated-error-icon-static", {
                alt: "Error",
            });
            this.errorContainer = Dom.div(
                { class: "validated-error" },
                errorIcon.node,
                this.errorContents,
            );
            Dom.hide(this.errorContainer);

            const onTextChange = () => {
                this.onInputChange();
                this.updateValidDateRanges();
                if (this.updateErrorTextCheckIfInvalid()) {
                    return;
                }

                const from = this.fromDatebox.getValue()?.lower;
                const to = this.toDatebox.getValue()?.lower;
                const curFilterValue = this.filter.getFilterValue();
                if (!curFilterValue) {
                    this.addFilterButton.setDisabled(!from && !to);
                    return;
                }

                const fromHasntChanged =
                    (!from && !curFilterValue?.from) || from === curFilterValue.from;
                const toHasntChanged = (!to && !curFilterValue?.to) || to === curFilterValue.to;
                this.addFilterButton.setDisabled(fromHasntChanged && toHasntChanged);
            };

            const dateBoxParams = {
                placeholder: "Enter date",
                required: false,
                onChange: onTextChange.bind(this),
                disableBuiltInDisplayError: true,
            };
            this.fromDatebox = new DateBox(dateBoxParams);
            this.toDatebox = new DateBox(dateBoxParams);
            this.toDestroy.push(this.fromDatebox, this.toDatebox);

            return Dom.div(
                { class: "filter-tooltip-dialog__term-textbox-container" },
                Dom.div(
                    { class: "datebox" },
                    Dom.div(
                        { class: "date-entry" },
                        Dom.div({ class: "semi-bold" }, "From"),
                        Dom.div({ class: "date-box" }, Dom.node(this.fromDatebox)),
                    ),
                    Dom.div(
                        { class: "date-entry" },
                        Dom.div({ class: "semi-bold" }, "To"),
                        Dom.div({ class: "date-box" }, Dom.node(this.toDatebox)),
                    ),
                ),
                this.errorContainer,
            );
        }

        override onPreOpen() {
            super.onPreOpen();
            this.updateValidDateRanges();
            this.updateErrorTextCheckIfInvalid();
        }

        private updateErrorTextCheckIfInvalid(): boolean {
            if (!this.fromDatebox.input.isValid() || !this.toDatebox.input.isValid()) {
                const errorText =
                    this.toDatebox.input.getErrorMessageIfInvalid()
                    || this.fromDatebox.input.getErrorMessageIfInvalid();
                if (errorText) {
                    this.errorContents.textContent = errorText as string;
                    Dom.show(this.errorContainer);
                }
                this.addFilterButton.setDisabled(true);
                return true;
            }

            Dom.hide(this.errorContainer);
            return false;
        }

        private getValidRange(): { start: number; end: number } {
            let start;
            let end;
            this.filter.table.store.getAll().forEach((obj) => {
                const value = obj[this.filter.definition.key] as number;
                if (value == null) {
                    // tests for both null and undefined
                    return;
                }
                if (!Is.defined(start) || value < start) {
                    start = value;
                }
                if (!Is.defined(end) || value > end) {
                    end = value;
                }
            });
            return { start, end };
        }

        private updateValidDateRanges(): void {
            const range = this.getValidRange();
            const toDateboxTime = this.toDatebox.getValue()?.lower;
            const fromDateboxTime = this.fromDatebox.getValue()?.lower;

            if (!range.start) {
                // If there is no valid range, i.e. either all objects in the table do not have the value
                // or if there are no objects in the table, handle special cases using only the dateboxes
                this.fromDatebox.resetMin();
                if (Is.defined(toDateboxTime)) {
                    this.fromDatebox.setMax({
                        lower: toDateboxTime,
                        precision: Precision.dateOnlyNoZone,
                    });
                } else {
                    this.fromDatebox.resetMax();
                }

                this.toDatebox.resetMax();
                if (Is.defined(fromDateboxTime)) {
                    this.toDatebox.setMin({
                        lower: fromDateboxTime,
                        precision: Precision.dateOnlyNoZone,
                    });
                } else {
                    this.toDatebox.resetMin();
                }
                return;
            }

            this.fromDatebox.setMin({
                lower: range.start,
                precision: Precision.dateOnlyNoZone,
            });

            this.fromDatebox.setMax({
                lower: toDateboxTime ? Math.min(toDateboxTime, range.end) : range.end,
                precision: Precision.dateOnlyNoZone,
            });

            this.toDatebox.setMin({
                lower: fromDateboxTime ? Math.max(range.start, fromDateboxTime) : range.start,
                precision: Precision.dateOnlyNoZone,
            });

            this.toDatebox.setMax({
                lower: range.end,
                precision: Precision.dateOnlyNoZone,
            });
        }

        protected override postConstruct() {
            // This prevents the filter popup from closing when a user clicks on the
            // date selector popup
            this.popover.connect.push(
                dojo_on(this.fromDatebox.popup.content, Input.press, (e) => {
                    eventUtil.stop(e);
                }),
                dojo_on(this.toDatebox.popup.content, Input.press, (e) => {
                    eventUtil.stop(e);
                }),
            );
        }

        protected getValue(): DateRange {
            return {
                from: this.fromDatebox.getValue()?.lower,
                to: this.toDatebox.getValue()?.lower,
            };
        }

        protected initialFocus(): void {
            // Nothing to focus here
        }

        protected updateDisplay(display: DateRange): void {
            this.fromDatebox.setValue(
                display
                    ? {
                          lower: display.from,
                          precision: Precision.dateOnlyNoZone,
                          format: DateUtil.MOMENT_JS_DATE_FORMAT[getProjectDateDisplayFormat()],
                      }
                    : null,
            );
            this.toDatebox.setValue(
                display
                    ? {
                          lower: display.to,
                          precision: Precision.dateOnlyNoZone,
                          format: DateUtil.MOMENT_JS_DATE_FORMAT[getProjectDateDisplayFormat()],
                      }
                    : null,
            );
        }

        toFilterDisplay(range: DateRange): string {
            const dateFormatter = (timestamp: number) =>
                DateUtil.displayFullDateWithFormat(
                    DateUtil.asDate(timestamp),
                    getProjectDateDisplayFormat(),
                    false,
                );
            if (!range.from) {
                return `Before ${dateFormatter(range.to)}`;
            }
            if (!range.to) {
                return `After ${dateFormatter(range.from)}`;
            }
            return `${dateFormatter(range.from)} - ${dateFormatter(range.to)}`;
        }

        hasInputValue(): boolean {
            return !!this.fromDatebox.getValue() || !!this.toDatebox.getValue();
        }

        clearInputs(): void {
            this.fromDatebox.setValue(null, true);
            this.toDatebox.setValue(null, true);
            Dom.hide(this.errorContainer);
            this.updateValidDateRanges();
            this.updateErrorTextCheckIfInvalid();
        }
    }

    export class Filter<OBJ extends Base.Object, FILTERDATA> {
        filterIcon: Button.IconButton;
        table: Table<OBJ, Table.RowData>;

        private headerPopover: FilterPopover<FILTERDATA>;

        private toDestroy: Util.Destroyable[] = [];

        constructor(
            public module: Table.FilterSortModule<OBJ>,
            public definition: FilterDef<OBJ, FILTERDATA>,
        ) {
            this.filterIcon = new Button.IconButton({
                iconClass: "filter-20",
                tooltip: `Filter by ${definition.column}`,
                onClick: () => {
                    const popover = this.getOrCreateFilterPopover();
                    popover.openAndFocus();
                },
            });
            this.toDestroy.push(this.filterIcon);
        }

        toggleFilterIcon(state: boolean) {
            this.filterIcon.setIconClass(state ? "filter-filled-blue-20" : "filter-20");
        }

        setTable(table: Table<OBJ, Table.RowData>): void {
            this.table = table;
        }

        scrollToAndOpenPopover(): void {
            this.table.scrollToColumn(this.definition.column);
            const popover = this.getOrCreateFilterPopover();
            popover.openAndFocus();
        }

        getOrCreateFilterPopover(): FilterPopover<FILTERDATA> {
            if (!this.headerPopover) {
                this.headerPopover = new this.definition.filterPopoverType(
                    this,
                    this.filterIcon.node,
                );
            }
            return this.headerPopover;
        }

        updateFilter(filterDataValue?: FILTERDATA): void {
            if (!filterDataValue) {
                this.module.removeFilter(this.definition.column);
            } else {
                this.module.addFilter(this.definition.column, filterDataValue);
            }
            this.table.updateFilter(null, null, () => {
                this.table.onFilterChange?.(this.module);
            });
        }

        getFilterValue(): FILTERDATA {
            return this.module.getFilteredValue(this.definition.column) as FILTERDATA;
        }

        clearInputs(): void {
            this.headerPopover.clearInputs();
        }

        hasInputValue(): boolean {
            return this.headerPopover.hasInputValue();
        }

        destroy(): void {
            Util.destroy(this.toDestroy);
        }
    }

    /**
     * Module that handles filtering/sorting for a table.
     * To have a table have sorting/filtering controls, pass in a module to the table's params.
     */
    export class FilterSortModule<OBJ extends Base.Object> {
        private columnFilterers: Map<string, Filter<OBJ, unknown>>;
        private columnSort: Sort | undefined;
        private cachedSortCmp: (a: OBJ, b: OBJ) => number;
        private sortButtons: Map<string, SortColumnHeader>;
        private defaultSort: Filtering.Sort | undefined;
        private defaultFilter: Filtering.Entry;
        useKeysetPagination = false;

        getUniqueSortableValue: ((obj: OBJ) => string) | undefined;
        onFilterUpdate: () => void;
        /**
         * Display bar for filters that shows a bubble for each applied filter, buttons to
         * edit them, and a "remove filters" button to remove all filters
         */
        filterDisplayBar: Table.FilterDisplayBar<OBJ>;

        private toDestroy: Util.Destroyable[] = [];

        private activeFilters: Map<string, unknown>;
        filter: (obj: OBJ) => boolean = (obj) => {
            for (const [column, filterValue] of this.activeFilters) {
                const filter = this.columnFilterers.get(column);
                if (filter && !filter.definition.matchesFilter(obj, filterValue)) {
                    return false;
                }
            }
            return true;
        };

        getRawSort(): Sort | undefined {
            return this.columnSort;
        }

        /**
         * Returns a Sort fot columnSort if we are currently sorting by a column;
         * otherwise defaultSort if we have a defined default; otherwise undefined
         */
        getSort(): Filtering.Sort | undefined {
            if (this.columnSort) {
                return {
                    column: this.columnSort.key,
                    direction: this.columnSort.direction,
                };
            }
            if (this.defaultSort) {
                return {
                    column: this.defaultSort.column,
                    direction: this.defaultSort.direction,
                };
            }
            return undefined;
        }

        populateParams(params: Filtering.Params): void {
            if (this.columnSort || !params.sort) {
                // try to add the sort parameter if we either:
                // 1. have an explicit column to sort by
                // 2. OR are currently sort-less (in which case we apply default sort)
                params.sort = this.getSort();
            }
            params.filters = this.getFilterEntries();
            params.includeTotal = true;
        }

        getFilterEntries(): Filtering.Entry[] {
            const filterEntries: Filtering.Entry[] = [];
            for (const [column, filterValue] of this.activeFilters) {
                const filter = this.columnFilterers.get(column);
                filter && filterEntries.push(filter.definition.toFilterEntry(filterValue));
            }
            // Make a deep copy so we don't accidentally mutate defaultFilter
            this.defaultFilter
                && filterEntries.push({
                    type: this.defaultFilter.type,
                    column: this.defaultFilter.column,
                    params: this.defaultFilter.params,
                });
            // Bake all params that are function calls
            filterEntries.forEach((entry) => {
                if (!Is.string(entry.params)) {
                    entry.params = entry.params();
                }
            });
            return filterEntries;
        }

        constructor(params: FilterParams<OBJ>) {
            this.columnFilterers = new Map();
            this.activeFilters = new Map();
            this.sortButtons = new Map();
            params.filters.forEach((filter) => {
                const filterObj = new Filter(this, filter);
                this.registerDestroyable(filterObj);
                this.columnFilterers.set(filter.column, filterObj);
            });
            this.filterDisplayBar = new Table.FilterDisplayBar<OBJ>(this, () =>
                this.onFilterUpdate(),
            );
            this.defaultSort = params.defaultSort;
            this.defaultFilter = params.defaultFilter;
            this.useKeysetPagination = params.useKeysetPagination ?? false;
            this.getUniqueSortableValue = params.getUniqueSortableValue;
        }

        createSortableHeader(
            column: string,
            key: string,
            onSort: (col: string) => void,
        ): SortColumnHeader {
            const headerButton = new SortColumnHeader(column, (newStateId) => {
                if (newStateId !== "unselected") {
                    this.sortButtons.forEach((otherIcon, otherColumn) => {
                        if (column === otherColumn || otherIcon.current().id === "unselected") {
                            return;
                        }
                        otherIcon.setState("unselected", true);
                    });
                    this.columnSort = {
                        column,
                        key,
                        direction: newStateId as "ASC" | "DESC",
                    };
                } else {
                    this.columnSort = undefined;
                }
                onSort(column);
            });
            this.sortButtons.set(column, headerButton);
            return headerButton;
        }

        hasFilterApplied(): boolean {
            return this.activeFilters.size > 0;
        }

        getColumnFilter(column: string): Filter<OBJ, unknown> | undefined {
            return this.columnFilterers.get(column);
        }

        setFilters(filters: Map<string, unknown>): void {
            this.activeFilters.clear();
            filters.forEach((v, k) => this.addFilter(k, v));
        }

        setSort(sort: Sort): void {
            this.columnSort = sort;
            const button = this.sortButtons.get(sort.column);
            button?.setState(sort.direction);
        }

        /**
         * Get a copy of activeFilters that can be used later with setFilters
         */
        getRawFilters(): Map<string, unknown> {
            const copy = new Map<string, unknown>();
            this.activeFilters.forEach((v, k) => copy.set(k, v));
            return copy;
        }

        addFilter(column: string, value: unknown): void {
            this.activeFilters.set(column, value);
            this.columnFilterers.get(column)?.toggleFilterIcon(true);
            this.filterDisplayBar.addFilter(column, value);
        }

        removeFilter(column: string): void {
            this.activeFilters.delete(column);
            this.columnFilterers.get(column)?.toggleFilterIcon(false);
            this.filterDisplayBar.removeFilter(column);
        }

        removeAllFilters(): void {
            this.activeFilters.forEach((_val, column) =>
                this.columnFilterers.get(column)?.toggleFilterIcon(false),
            );
            this.filterDisplayBar.removeAll();
            this.activeFilters.clear();
        }

        getFilteredValue(column: string): unknown {
            return this.activeFilters.get(column);
        }

        getSortCmp(defaultCmp: (a, b) => number = Cmp.full): (a: OBJ, b: OBJ) => number {
            if (!this.cachedSortCmp) {
                this.cachedSortCmp = (a, b) => {
                    if (!this.columnSort) {
                        return defaultCmp(a, b);
                    }

                    const va = a[this.columnSort.key];
                    const vb = b[this.columnSort.key];
                    let cmpValue;
                    // Use of '==' instead of 'Is.defined' or '===' is intentional here, as we want
                    // to explicitly test for "is null or undefined"
                    if (va == null && vb == null) {
                        cmpValue = defaultCmp(a, b);
                    } else if (va == null) {
                        cmpValue = -1;
                    } else if (vb == null) {
                        cmpValue = 1;
                    } else {
                        const cmp =
                            this.columnFilterers.get(this.columnSort.column).definition.cmp
                            ?? defaultCmp;
                        cmpValue = cmp(va, vb);
                    }
                    return cmpValue * (this.columnSort.direction === "DESC" ? -1 : 1);
                };
            }
            return this.cachedSortCmp;
        }

        registerDestroyable(destroyable: Util.Destroyable): void {
            this.toDestroy.push(destroyable);
        }

        destroy(): void {
            Util.destroy(this.toDestroy);
        }
    }

    export type TableRowExpanderParams = Pick<
        SwitchingIconButtonParams,
        "className" | "onChange" | "state" | "suppressDojoEvent"
    >;

    export function createTableRowExpander({
        className,
        onChange,
        state = false,
        suppressDojoEvent,
    }: TableRowExpanderParams): SwitchingIconButton {
        return new SwitchingIconButton({
            onClass: "chevron-down-20",
            offClass: "chevron-right-20",
            className,
            onTooltip: "Collapse",
            offTooltip: "Expand",
            state,
            onChange,
            suppressDojoEvent,
        });
    }

    export interface FilterParams<OBJ extends Base.Object> {
        filters: FilterDef<OBJ, unknown>[];
        defaultSort?: Filtering.Sort;
        // This value is not optional since we almost always want to filter on something that
        // restricts permissions, such as by databaseId of a project. Pass an explicit null if
        // you are purposefully not including such a filter.
        defaultFilter: Filtering.Entry;
        // If set to true, filter requests on the back-end will be preferentially resolved using keyset
        // pagination instead of limit offset.
        useKeysetPagination?: boolean;
        // Getter for the unique sortable value, as defined by its column in FilterContext.java.
        getUniqueSortableValue?: (obj: OBJ) => string;
    }

    export interface FilterDef<OBJ extends Base.Object, FILTERDATA> {
        column: string;
        key: string;
        matchesFilter: (obj: OBJ, valueToTest: FILTERDATA) => boolean;
        cmp: (a, b) => number;
        filterPopoverType: FilterPopoverType<FILTERDATA>;
        toFilterEntry: (filterValue: FILTERDATA) => Filtering.Entry;
    }

    export interface DateRange {
        from: number;
        to: number;
    }

    export interface Sort {
        column: string;
        key: string;
        direction: "ASC" | "DESC";
    }

    export interface PaginationBarData {
        pageDisplay: HTMLElement;
        indexDisplay: HTMLElement;
        firstPageButton: Button.IconButton;
        prevPageButton: Button.IconButton;
        lastPageButton: Button.IconButton;
        nextPageButton: Button.IconButton;
    }

    export interface ColumnHeaderData {
        div: HTMLElement;
        header: string;
    }
}

Table.prototype.entryClass = Table.LegacyTableEntry;

export = Table;
